import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'xaridlar',
    initialState: {
        xaridlar: null,
        current:false,
        xaridlarjami:0,
        xaridsumma:0,
        xaridqarz:0,
        xaridlarcost:{},
        xaridMahsulot:null,
        xaridTotal: 0,
        xaridOne: {},
        xaridProductList: [],
        loading : false,
        saveBoolean:false,
        getBoolean:false,
        message:'',
        purchaseProductList: []
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.xaridlar = action.payload.object
            }else {
                state.message = action.payload.message
                state.xaridlar =null
            }
            state.loading = !state.loading
            state.saveBoolean = false
        },
        getFromOne: (state, action) => {
            if (action.payload.success){
                state.xaridOne = action.payload.object.purchase
                state.xaridProductList = action.payload.object.purchaseProductList
                state.purchaseProductList = action.payload.object.purchaseProductGetDtoList
            }else {
                toast.error(action.payload.message)
            }
            state.getBoolean = !state.getBoolean
            state.loading = !state.loading
            state.saveBoolean=false
        },
        getProductType:(state,action)=>{

            if (action.payload.success){
                state.xaridMahsulot = null
                state.xaridMahsulot = action.payload.object
                state.loading = true
            }
            else{
                state.loading = false
                state.xaridMahsulot = null
                state.message = action.payload.message
            }
            state.saveBoolean = false

        },
        getFromcost: (state, action) => {
            if (action.payload.success){
                state.xaridlarcost = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.saveBoolean=!state.saveBoolean
        },
        getFromTotal:(state,action)=>{
            if (action.payload.success){
                state.xaridTotal = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.saveBoolean=!state.saveBoolean
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                state.saveBoolean = true
                toast.success('Xarid qilindi !')
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                state.saveBoolean =true
                toast.success('Xarid taxrirlandi !')
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                state.current=!state.current
                toast.error(`O'chirildi`)
            }else {
                toast.error(action.payload.message)
            }

        }
    }
});

export const getXaridByBusiness=(data)=>apiCall({
    url: '/purchase/get-by-business/'+data.businessId,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const getXaridByBranch=(data)=>apiCall({
    url: '/purchase/get-by-branch/'+data.branchId,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getXaridCost=(data)=>apiCall({
    url: '/purchase/get-by-business/'+data,
    method:'get',
    onSuccess: slice.actions.getFromcost.type,
    onFail: slice.actions.getFromcost.type
});

export const getXaridProductType=(data)=>apiCall({
    url: '/product/get-by-branch-for-purchase-trade/'+data,
    method:'get',
    onSuccess: slice.actions.getProductType.type,
    onFail: slice.actions.getProductType.type,
});

export const getXaridProductPageSize=(data, searchValue = "")=>apiCall({
    url: `/product/get-by-branch-for-trade/${data.branchId}?page=${data.page}&size=${data.size}&searchValue=${searchValue}`,
    method:'get',
    onSuccess: slice.actions.getProductType.type,
    onFail: slice.actions.getProductType.type,
});

export const getXarid5=(data)=>apiCall({
    url: '/purchase/'+data,
    method:'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
});
export const getPurchaseView=(data)=>apiCall({
    url: '/purchase/view/'+data,
    method:'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
});


export const saveXarid=(data)=>apiCall({
    url: '/purchase',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editXarid=(data)=>apiCall({
    url: '/purchase/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteXarid=(data)=>apiCall({
    url: '/purchase/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer
