import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'tariff',
    initialState: {
        tariff: [],
        permissionList:[],
        oneTariff:null,
        current:false,
        tariffchoose:[]

    },
    reducers: {
        getFrom: (state, action) => {
            state.tariff = action.payload.object
        },
        getFromPermissions: (state, action) => {
                if (action.payload.success){
                    state.permissionList = action.payload.object
                }
                else{
                    state.permissionList = []
                }
        },
        getFromChoose: (state, action) => {
            state.tariffchoose = action.payload.object
        },
        getFromById: (state, action) => {
            state.oneTariff = action.payload.object
            state.current=!state.current
        },
        savefrom: (state,action) => {
            state.current=!state.current
            toast.success('Saqlandi')
        },
        editfrom: (state,action) => {
            state.current=!state.current
            toast.success('Tahrirlandi')
        },
        deletefrom:(state,action)=>{
            state.current=!state.current
            toast.success('Ro\'yhatdan o`chirildi')
        }
    }
});

export const getTariff=()=>apiCall({
    url: '/tariff',
    method:'get',
    onSuccess: slice.actions.getFrom.type
});

export const getTariffPermission=(data)=>apiCall({
    url: '/role/get-role-tariff/'+data,
    method:'get',
    onSuccess: slice.actions.getFromPermissions.type,
    onFail: slice.actions.getFromPermissions.type
});

export const getTariffById=(data)=>apiCall({
    url: '/tariff/getById/'+data,
    method:'get',
    onSuccess: slice.actions.getFromById.type
});

export const saveTariff=(data)=>apiCall({
    url: '/tariff',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type
});
export const getTariffChoose=()=>apiCall({
    url: '/tariff/getToChooseATariff',
    method:'get',
    onSuccess: slice.actions.getFromChoose.type
});

export const editTariff=(data)=>apiCall({
    url: '/tariff/'+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.savefrom.type
});

export const deleteTariff=(id)=>apiCall({
    url: '/tariff/'+id,
    method:'delete',
    onSuccess: slice.actions.savefrom.type
});

export default slice.reducer
