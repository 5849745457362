import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'bolimlar',
    initialState: {
        bolimlar: null,
        current :false,
        ManyCurrent:false,
        getBoolean: false,
        saveBoolean:false,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.bolimlar = action.payload.object
                state.ManyCurrent = !state.ManyCurrent
            }else {
                state.message = action.payload.message
                state.bolimlar = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Bo`lim qo`shildi')
                state.saveBoolean = true
            }else {
                toast.success(action.payload.message)
            }
            state.current=!state.current

        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Bo`lim tahrirlandi')
                state.saveBoolean = true
            }else {
                toast.success(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('Bo`lim o`chirildi')
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        }
    }
});



export const getBolim=(data)=>apiCall({
    url: '/category/get-parent_category_by_business_id/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});


export const saveBolim=(data)=>apiCall({
    url: '/category',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});

export const editBolim=(data)=>apiCall({
    url: '/category/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteBolim=(data)=>apiCall({
    url: '/category/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer