import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'production',
    initialState: {
        production: null,
        productionTotal: null,
        oneProduction: null,
        contentProductList: null,
        costGetDtoList:null,
        current: false,
        getBoolean: false,
        message: '',
        saveBoolean:false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.production = action.payload.object?.getAllProduction
                state.productionTotal = action.payload.object?.totalItems
            }else {
                state.production = null
                state.productionTotal = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean=false
        },
        getFromById: (state, action) => {
            if (action.payload.success){
                state.current = !state.current
                let a = []
                a.push(action.payload.object.production)
                state.oneProduction = a
                state.contentProductList = action.payload.object.contentProductList
                state.costGetDtoList = action.payload.object.costGetDtoList
            }else {
                state.message = action.payload.message
                state.oneProduction = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean=true
                toast.success('Saqlandi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        editfrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean=true
                toast.success('Tahrirlandi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success){
                state.current = !state.current
                toast.success('Ro\'yhatdan o`chirildi')
            }else {
                toast.error(action.payload.message)
            }

        }
    }
});

export const getProduction = (data) => apiCall({
    url: `/production/by-branch/${data.branchId}`,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getProductionById = (data) => apiCall({
    url: '/production/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromById.type,
    onFail: slice.actions.getFromById.type
});

export const saveProduction = (data) => apiCall({
    url: '/production',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});


export default slice.reducer
