import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'savdotulov',
    initialState: {
        savdotulov: null,
        savdoOne:[],
        savdoTwo:[],
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.savdotulov = action.payload.object
            }
            else{
                state.savdotulov   = null
                state.message = action.payload.message
            }
        },
        getFrom2: (state, action) => {
            let a = []
                a.push(action.payload.object.trade)
            state.savdoOne = a
            state.savdoTwo = action.payload.object.tradeProductList
        }
    }
});

export const getSavdotulov=(data)=>apiCall({
    url: '/trade/product/get-all-trade/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const getOneSavdo=(data)=>apiCall({
    url:'/trade/'+data,
    method: 'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail: slice.actions.getFrom2.type
});

export const getSavdoReport=(data)=>apiCall({
    url:`/reports/trade-by-branch/${data.branchId}?${data.customer ? `customerId=${data.customer}`:'' }${data.payMethod ? `&payMethodId=${data.payMethod}`:''}${data.startDate ? `&startDate=${data.startDate}`:''}${data.endDate ? `&endDate=${data.endDate}`:''}`,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom
});
export default slice.reducer
