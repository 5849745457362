import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'stages',
    initialState: {
        stages:null,
        stagesPages:null,
        stageOne: null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.stages = action.payload.object
            }
            else{
                state.stages = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFromPage: (state, action) => {
            if (action.payload.success){
                state.stagesPages = action.payload.object
            }
            else{
                state.stagesPages = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getOne:(state,action)=>{
            if (action.payload.success){
                state.stageOne = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.getBoolean =!state.getBoolean
            state.saveBoolean=false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});

export const getStage=(data)=>apiCall({
    url: '/stage/get-by-branch/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getStagePage=(data)=>apiCall({
    url: '/stage/get-by-branchPageable/'+data.branchId,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFromPage.type,
    onFail: slice.actions.getFromPage.type,
});
export const saveStage=(data)=>apiCall({
    url: '/stage',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const deleteStage=(data)=>apiCall({
    url: '/stage/'+data,
    method:'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer