import {configureStore} from "@reduxjs/toolkit";
import {api} from './middleware';
import users from "./reducer/users";
import functionreducer from "./reducer/functionreducer";
import XodimReducer from "./components/SotibOlish/Sidebar/Hodimlar/reducer/XodimReducer";
import LavozimReducer from "./components/SotibOlish/Sidebar/Hodimlar/reducer/LavozimReducer";
import XodimSavdoReducer from "./components/SotibOlish/Sidebar/Hodimlar/reducer/XodimSavdoReducer";
import TaminotReducer from "./components/SotibOlish/Sidebar/Hamkorlar/reducer/TaminotReducer";
import MijozGuruxReducer from "./components/SotibOlish/Sidebar/Hamkorlar/reducer/MijozGuruxReducer";
import SotuvNarxiReducer from "./components/SotibOlish/Sidebar/Maxsulotlar/reducer/SotuvNarxiReducer";
import BolimReducer from "./components/SotibOlish/Sidebar/Maxsulotlar/reducer/BolimReducer";
import FirmaReducer from "./components/SotibOlish/Sidebar/Maxsulotlar/reducer/FirmaReducer";
import XaridReducer from "./components/SotibOlish/Sidebar/Haridlar/reducer/XaridReducer";
import OtkazmaReducer from "./components/SotibOlish/Sidebar/Baza/reducer/OtkazmaReducer";
import XarajatlarReducer from "./components/SotibOlish/Sidebar/Xarajatlar/reducer/XarajatlarReducer";
import XarajatTurlariReducer from "./components/SotibOlish/Sidebar/Xarajatlar/reducer/XarajatTurlariReducer";
import FoydaZararReducer from "./components/SotibOlish/Sidebar/Xisobotlar/reducer/FoydaZararReducer";
import XaridlarXisobotiReducer from "./components/SotibOlish/Sidebar/Xisobotlar/reducer/XaridlarXisobotiReducer";
import MijozHisobotiReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/MijozHisobotiReducer'
import XarajatXisobotReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/XarajatXisobotReducer'
import SavdodagiTulovReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/SavdodagiTulovReducer'
import MaxsulotxisobotReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/MaxsulotxisobotReducer'
import KopsotilgantovarlarReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/KopsotilgantovarlarReducer'
import OtkazmalarxisobotiReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/OtkazmalarxisobotiReducer'
import QoldiqlarxisobotiReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/QoldiqlarxisobotiReducer'
import SoliqlarxisobotiReducer from './components/SotibOlish/Sidebar/Xisobotlar/reducer/SoliqlarxisobotiReducer'
import MaxsulotlarRoyxariReducer from "./components/SotibOlish/Sidebar/Maxsulotlar/reducer/MaxsulotlarRoyxariReducer";
import SavdoQoshishReducer from "./components/SotibOlish/Sidebar/Savdo/reducer/SavdoQoshishReducer";
import branchreducer from "./reducer/branchreducer";
import kgreducer from "./reducer/kgreducer";
import tolovreducer from "./reducer/tolovreducer";
import PayReducer from "./reducer/PayReducer";
import photoreducer from "./reducer/photoreducer";
import MijozlarGuruhReducer from "./components/SotibOlish/Sidebar/Hamkorlar/reducer/MijozlarGuruhReducer";
import QarzuzishReducer from "./components/SotibOlish/Sidebar/Hamkorlar/reducer/QarzuzishReducer";
import ValyutaReducer from "./components/SotibOlish/Sidebar/Settings/DukonSozlamalari/reducers/ValyutaReducer";
import SoliqReducer from "./components/SotibOlish/Sidebar/Settings/DukonSozlamalari/reducers/SoliqReducer";
import Ichkibolimred from "./components/SotibOlish/Sidebar/Maxsulotlar/reducer/Ichkibolimred";
import addressReducer from "./reducer/addressReducer";
import ExchangeStatusR from "./reducer/ExchangeStatusR";
import MahsulotTurlariReducer from "./components/SotibOlish/Sidebar/Maxsulotlar/reducer/MahsulotTurlariReducer";
import accountreducer from "./reducer/accountreducer";
import ReportsReducer from "./components/SotibOlish/Sidebar/Xisobotlar/reducer/ReportsReducer";
import tariffReducer from "./reducer/tariffReducer";
import contentReducer from "./reducer/contentReducer";
import productionReducer from "./reducer/productionReducer";
import Reducer from "./components/SotibOlish/Sidebar/Xisobotlar/foydaZarar/Route/reducer/Reducer";
import allbusinessreducer from "./components/SotibOlish/Sidebar/SUPERADMIN/reducers/allbusinessreducer";
import subscripreducer from "./components/SotibOlish/Sidebar/SUPERADMIN/reducers/subscripreducer";
import shopreducer from "./components/Pricing/ShopInfo/shopreducer";
import SuperAdminReducer from "./components/SotibOlish/Sidebar/SUPERADMIN/reducers/SuperAdminReducer";
import titleReducer from "./reducer/titleReducer";
import notificationReducer from "./reducer/notificationReducer";
import LidStatusReducer from "./components/SotibOlish/Sidebar/Lidlar/Lids/reducer/LidStatusReducer";
import taskStatusReducer from "./reducer/taskStatusReducer";
import workTimeReducer from "./reducer/workTimeReducer";
import LidFieldReducer from "./components/SotibOlish/Sidebar/Lidlar/Lids/reducer/LidFieldReducer";
import LidMessengersReducer from "./components/SotibOlish/Sidebar/Lidlar/Lids/reducer/LidMessengersReducer";
import FormReducer from "./components/SotibOlish/Sidebar/Lidlar/Lids/reducer/FormReducer";
import LidReducer from "./components/SotibOlish/Sidebar/Lidlar/Lids/reducer/LidReducer";
import bonusReducer from "./reducer/bonusReducer";
import checkReducer from "./reducer/checkReducer";
import ProjectReducer from "./reducer/ProjectReducer";
import StageReducer from "./reducer/StageReducer";
import ProjectTypeReducer from "./reducer/ProjectTypeReducer";
import prizeReducer from "./reducer/prizeReducer";
import taskTypeReducer from "./reducer/taskTypeReducer";
import TaskReducer from "./reducer/TaskReducer";
import SalaryCountReducer from "./components/SotibOlish/Sidebar/Hodimlar/reducer/SalaryCountReducer";
import SalaryReducer from "./reducer/SalaryReducer";
import DarsliklarReducer from "./components/SotibOlish/Sidebar/Darsliklar/reducer/DarsliklarReducer";
import filesReducer from "./reducer/filesReducer";
import LessonUserReducer from "./components/SotibOlish/Sidebar/Darsliklar/reducer/LessonUserReducer";
import TestReducer from "./components/SotibOlish/Sidebar/Darsliklar/reducer/TestReducer";
import selectLidReducer from "./components/SotibOlish/Sidebar/Lidlar/Lids/reducer/selectLidReducer";
import JobReducer from "./components/SotibOlish/Sidebar/HR/reducer/JobReducer";
import balanceReducer from "./reducer/balanceReducer";
import shablonReducer from "./reducer/shablonReducer";
import NavigatorReducer from "./components/SotibOlish/Sidebar/BiznesNavigator/reducer/NavigatorReducer";
import CarReducer from "./components/SotibOlish/Sidebar/Baza/reducer/CarReducer";
import ExchangeReducer from "./components/SotibOlish/Sidebar/Baza/reducer/ExchangeReducer";
import holdOnReducer from "./reducer/holdOnReducer";
import lossReducer from "./reducer/lossReducer";
import productionCostReducer from "./reducer/productionCostReducer";
export default configureStore({
    reducer:{
        users,functionreducer,XodimReducer,LavozimReducer,XodimSavdoReducer,TaminotReducer,tolovreducer,photoreducer,
        MijozGuruxReducer,SotuvNarxiReducer,BolimReducer,FirmaReducer,XaridReducer,SuperAdminReducer,
        OtkazmaReducer,XarajatlarReducer,XarajatTurlariReducer,FoydaZararReducer,XaridlarXisobotiReducer,
        MijozHisobotiReducer,XarajatXisobotReducer,SavdodagiTulovReducer,MaxsulotxisobotReducer,KopsotilgantovarlarReducer,
        OtkazmalarxisobotiReducer,QoldiqlarxisobotiReducer,SoliqlarxisobotiReducer,MaxsulotlarRoyxariReducer,branchreducer,kgreducer,SavdoQoshishReducer,
        PayReducer, MijozlarGuruhReducer,QarzuzishReducer,ValyutaReducer,SoliqReducer,Ichkibolimred,addressReducer,ExchangeStatusR,MahsulotTurlariReducer,accountreducer,
        ReportsReducer,tariffReducer,contentReducer,productionReducer,Reducer,allbusinessreducer,subscripreducer,shopreducer,LidStatusReducer,
        notificationReducer,titleReducer,taskStatusReducer,LidFieldReducer,workTimeReducer,LidMessengersReducer,FormReducer,LidReducer,bonusReducer,checkReducer,
        ProjectReducer,StageReducer,ProjectTypeReducer,prizeReducer,taskTypeReducer,TaskReducer,SalaryCountReducer,SalaryReducer, DarsliklarReducer, filesReducer,
        LessonUserReducer, TestReducer,selectLidReducer,JobReducer, balanceReducer, shablonReducer,NavigatorReducer,CarReducer,ExchangeReducer,holdOnReducer,
        lossReducer,productionCostReducer
    },
        middleware:[api]
})
