import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'mijozGuruh',
    initialState: {
        mijozGuruh: null,
        current:false,
        message:'',
        getBoolean: false,
        saveBoolean:false,
        deleteBoolean: false,
        status: 0
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.mijozGuruh = action.payload.object
            }else {
                state.mijozGuruh = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state,action) => {
            if (action.payload.success){

                toast.success('Qo`shildi')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current

        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Guruh tahrirlandi !')
                state.saveBoolean = true

            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('Guruh o`chirildi!')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
            state.deleteBoolean=true
        }
    }
});

export const getMijozLarGuruh=(data)=>apiCall({
    url: '/customerGroup/get/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const saveMijozLarGurux=(data)=>apiCall({
    url: '/customerGroup',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editMijozLarGurux=(data)=>apiCall({
    url: '/customerGroup/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteMijozLarGuruh=(data)=>apiCall({
    url: '/customerGroup/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer
