import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'title',
    initialState: {
        title:null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.title = action.payload.object
            }
            else{
                state.title = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});

export const getTitle=(data)=>apiCall({
    url: '/course',
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const saveTitle=(data)=>apiCall({
    url: '/course',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const editTitle=(data)=>apiCall({
    url: '/course/'+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const deleteTitle=(data)=>apiCall({
    url: '/course/'+data,
    method:'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer