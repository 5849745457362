import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'xarajatturlari',
    initialState: {
        xarajatturlari: null,
        counter:false,
        message:'',
        getOutlayBool:false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.xarajatturlari = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.xarajatturlari = null
            }
            state.getOutlayBool = !state.getOutlayBool
            },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.counter=!state.counter
            }else{
                toast.warning(action.payload.message)
            }

        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Tahrirlandi')
                state.counter=!state.counter
            }else{
                toast.warning(action.payload.message)
            }

        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('O\'chirildi')
                state.counter=!state.counter
            }else{
                toast.warning(action.payload.message)
            }
        }
    }
});

export const getXarajatlarTurlari=(data)=>apiCall({
    url: '/outlayCategory/get-by-businessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getXarajatlarTurlariByBranch=(data)=>apiCall({
    url: '/outlayCategory/get-by-branchId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const saveXarajatlarTurlari=(data)=>apiCall({
    url: '/outlayCategory',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});

export const editXarajatlarTurlari=(data)=>apiCall({
    url: '/outlayCategory/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteXarajatlarTurlari=(data)=>apiCall({
    url: '/outlayCategory/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer