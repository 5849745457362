import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'workTime',
    initialState: {
        workUser:null,
        workUserMonth:null,
        workTime: null,
        workTimeList: null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        message:''
    },
    reducers: {
        getFromTime: (state, action) => {
            if (action.payload.success){
                state.workTime = action.payload.object

                let a = []
                action.payload.object.map(item=>{
                    a.push(item.timestampList)
                })
                state.workTimeList = a
            }
            else{
                state.workTime = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFrom: (state, action) => {
            if (action.payload.success){
                state.workUser = action.payload.object
            }
            else{
                state.workUser = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        getFromMonth:(state,action)=>{
            if (action.payload.success){
                state.workUserMonth = action.payload.object
            }
            else{
                state.workUserMonth = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        }
    }
});

export const getTimeList=(data)=>apiCall({
    url: '/workTime/by-branch-come-work/'+data,
    method:'get',
    onSuccess: slice.actions.getFromTime.type,
    onFail: slice.actions.getFromTime.type,
});
export const getWorkUser=(data)=>apiCall({
    url: '/workTime/by-users-on-work/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const workUserArrive=(data)=>apiCall({
    url: '/workTime/arrive',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const  workUserLeave=(data)=>apiCall({
    url: '/workTime/leave',
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const getWorkUserMonth=(data)=>apiCall({
    url: `/workTime/by-user-last-month/${data.userId}?branchId=${data.branchId}`,
    method:'get',
    onSuccess: slice.actions.getFromMonth.type,
    onFail: slice.actions.getFromMonth.type,
});

export default slice.reducer
