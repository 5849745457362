import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'navigator',
    initialState: {
        navigator: null,
        averageSum:null,
        navigatorProcess:null,
        current: false,
        message: '',
        getNavigatorBool: false,
        getNavigatorProcessBool: false,
        saveNavigatorBool: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.navigator = action.payload.object
            } else {
                state.navigator= null
                state.message = action.payload.message

            }
            state.getNavigatorBool = !state.getNavigatorBool
            state.saveNavigatorBool = false
        },
        getFromAverage: (state, action) => {
            if (action.payload.success) {
                state.averageSum = action.payload.object
            } else {
                state.averageSum= null
                state.message = action.payload.message

            }
            state.getNavigatorBool = !state.getNavigatorBool
            state.saveNavigatorBool = false
        },
        getFromNavigatorProcess: (state, action) => {
            if (action.payload.success) {
                state.navigatorProcess = action.payload.object
            } else {
                state.navigatorProcess = null
                state.message = action.payload.message

            }
            state.getNavigatorProcessBool = !state.getNavigatorProcessBool
            state.saveNavigatorBool = false
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma saqlandi')
                state.current = !state.current
                state.saveNavigatorBool = true
            } else {
                toast.error(action.payload.message)
            }
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma o\'chirildi')
                state.current = !state.current
            } else {
                toast.error(action.payload.message)
            }

        }
    }
});

export const getNavigator = (data) => apiCall({
    url: '/navigation/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getNavigatorProcess = (data) => apiCall({
    url: '/navigationProcess/' + data.branchId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFromNavigatorProcess.type,
    onFail: slice.actions.getFromNavigatorProcess.type,
});

export const getNavigatorProcessUpdate = (data) => apiCall({
    url: '/navigationProcess/update/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromNavigatorProcess.type,
    onFail: slice.actions.getFromNavigatorProcess.type,
});
export const getNavigatorAverage = (data) => apiCall({
    url: '/navigation/averageSell/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromAverage.type,
    onFail: slice.actions.getFromAverage.type,
});
export const saveNavigator = (data) => apiCall({
    url: '/navigation',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});


export const deleteNavigator = (data) => apiCall({
    url: '/navigation/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer