import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'trades',
    initialState: {
        trades:null,
        tradeHistory:null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        message:'',
        allbalance:null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.trades = action.payload.object
            }
            else{
                state.trades = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getAllBalance:(state,action)=>{
            if (action.payload.success){
                state.trades=action.payload.object
            }  else {
                state.trades = null
            }
        },
        getHistory:(state,action)=>{
            if (action.payload.success){
                state.tradeHistory = action.payload.object
            } else {
                state.tradeHistory = null
            }
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        saveFrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },

    }
});

export const getBalance=(data)=>apiCall({
    url: '/balance/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getallbalance=(data)=>apiCall({
    url: '/balance/business/'+data,
    method:'get',
    onSuccess: slice.actions.getAllBalance.type,
    onFail: slice.actions.getAllBalance.type,
});
export const getTradeHistory=(data)=>apiCall({
    url: `/balance-history/get-by-all-branchId/${data.branchId}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getHistory.type,
    onFail: slice.actions.getHistory.type,
});
export const  editBalance=(data)=>apiCall({
    url: '/balance/'+data.branchId,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const  saveBalance=(data)=>apiCall({
    url: '/'+data,
    method:'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type,
});

export default slice.reducer