import React, {useEffect, useState} from 'react';
import FormReducer, {getOneForm} from "../Lids/reducer/FormReducer";
import {connect} from "react-redux";
import selectLidReducer, {getLidSelect} from "../Lids/reducer/selectLidReducer";
import LidReducer, {saveLid} from "../Lids/reducer/LidReducer";
import {useForm} from "react-hook-form";
import {Link, useHistory} from "react-router-dom";
import users from "../../../../../reducer/users";
import {useTranslation} from 'react-i18next';

function Handle({match,getOneForm,FormReducer,users,LidReducer,saveLid,
                    getLidSelect,selectLidReducer}) {

    useEffect(()=>{
        getOneForm(match.params.sourceId)
        getLidSelect(users.businessId)
    },[])
    const {t} = useTranslation();


    const {register, setValue, resetField, reset, handleSubmit, formState: {errors}} = useForm()
    const history = useHistory()
    const [saveFormId, setSaveFormId] = useState(null)
    const [sendTrue, setSendTrue] = useState(false)
    const [linkTrue, setLinkTrue] = useState(false)
    const [saveModal, setSaveModal] = useState(false)
    const [selectName, setSelectName] = useState('')

    function onSubmit(data) {
        saveLid({
            businessId: users.businessId, values: data, formId: match.params.sourceId,
        })
        setSaveModal(true)
    }

    useEffect(() => {
        if (LidReducer.saveBoolean) {
            setLinkTrue(true)
            // history.push('/headerthird/lidlar')
        }
        setSaveModal(false)
    }, [LidReducer.current])


    return (
        <div className={'row'}>
            {
                !linkTrue?
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4 className={'text-center mt-3 mb-3'}>{t('ol.163')}</h4>
                    {
                        FormReducer.oneList ?
                            FormReducer.oneList.map((item, index) => <div className={'col-md-12'}>
                                    <div key={index} style={{border:'1px outset darkcyan'}} className={'p-4 mb-3 col-md-8 offset-2'}>

                                        <label htmlFor="">{item.name}</label>
                                        {item.valueType === 'SELECT' ?
                                            <select {...register((item.id), {required: true})}
                                                // value={selectName} onChange={(e)=>setSelectName(e.target.value)}
                                                    className={'form-control mt-3'}>
                                                {
                                                    selectLidReducer.lidSelect?
                                                        selectLidReducer.lidSelect.filter((val) => val.lidFieldId === item.id).map(i =>
                                                            <option value={i.name}>{i.name}</option>):''
                                                }
                                            </select>
                                            :
                                            item.valueType === 'INTEGER' ?
                                                <input
                                                    {...register((item.id), {required: true})}
                                                    placeholder={errors[item.id] ? errors[item.id].type === "required" && item.name + " is required" : item.name}
                                                    type={"number"}
                                                    className={'form-control'}/> :
                                                <input
                                                    {...register((item.id), {required: true})}
                                                    placeholder={errors[item.id] ? errors[item.id].type === "required" && item.name + " is required" : item.name}
                                                    type={item.valueType}
                                                    className={'form-control'}/>

                                        }

                                    </div>
                            </div>

                            ) : <h4 className={'text-center'}>NOT FOUND</h4>
                    }
                    <div className="offset-2 col-md-8">
                        <button className={'btn btn-outline-primary mt-2 form-control'} type={'submit'}>{t('ol.49')}</button>
                    </div>
                </form>:
                    <div className={'text-center mt-4 border border-2'}>
                        <Link to={'/headerthird/lidlar'}>
                            <button onClick={()=>setLinkTrue(false)} className={'btn text-center btn-outline-primary'}>{t('ol.70')}</button>
                        </Link>
                    </div>
            }

        </div>
    );
}

export default connect((FormReducer,selectLidReducer,LidReducer, users),{getOneForm,getLidSelect,saveLid}) (Handle);
