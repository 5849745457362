import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'allbusiness',
    initialState: {
        business: [],
        current: false,
        onebusiness: [],
        addBusinessSuccess: false,
        checkBusiness: '',
        checkUser: '',
        status: null,
        excistBusiness: '',
        excistUser: '',
        getBoolean: false,
        saveBoolean: false,
        message: '',
        businessMinusShop: false,
        businessMinusSHopBoolean: false,
        changeMinusBoolean: false,
        exchangeProductByConfirmation:false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.business = action.payload.object
            } else {
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFrom2: (state, action) => {
            if (action.payload.success) {
                state.onebusiness = action.payload.object
                state.businessMinusSHop = action.payload.object?.saleMinus
                state.exchangeProductByConfirmation = action.payload.object?.exchangeProductByConfirmation
            } else {
                state.message = action.payload.message
            }
            state.changeMinusBoolean = false
            state.businessMinusSHopBoolean = !state.businessMinusSHopBoolean

        },
        saveFrom: (state, action) => {
            if (action.payload.success) {
                // state.current=!state.current
                state.addBusinessSuccess = true
                state.saveBoolean = true
                toast.success(`Qo'shildi`)
            } else if (action.payload.status === 500) {
                state.status = action.payload.status
            } else {
                state.addBusinessSuccess = false
                toast.warning(action.payload.message)
            }
            state.current = !state.current
        },
        checkBusiness: (state, action) => {
            if (action.payload.success) {
                state.checkBusiness = action.payload.object
            } else {
                toast.error(action.payload.message)
                state.excistBusiness = action.payload.message
            }
        },
        checkUser: (state, action) => {
            if (action.payload.success) {
                state.checkUser = action.payload.object
            } else {
                toast.error(action.payload.message)
                state.excistUser = action.payload.message
            }
        },
        editFrom: (state, action) => {
            if (action.payload.success) {
                toast.success(`Tahrirlandi`)

            } else {
                toast.success(action.payload.message)
            }
            state.current = !state.current
            state.changeMinusBoolean = true
        },
        deleteFrom: (state, action) => {
            state.current = !state.current
            toast.error(`O'chirildi`)
        }
    }

});

export const getAllBusiness = () => apiCall({
    url: '/business/all',
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getOneBusiness = (data) => apiCall({
    url: '/business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail: slice.actions.getFrom2.type
});
export const checkBusiness = (data) => apiCall({
    url: '/business/checkBusinessName',
    method: 'post',
    data,
    onSuccess: slice.actions.checkBusiness.type,
    onFail: slice.actions.checkBusiness.type
});
export const editExchangeSettings = (data) => apiCall({
    url: `/business/turn-exchange-product/${data.businessId}?isTurn=${data.isTurn}`,
    method: 'put',
    data,
    onSuccess: slice.actions.editFrom.type,
    onFail: slice.actions.editFrom.type
});
export const checkUser = (data) => apiCall({
    url: '/business/checkUsername',
    method: 'post',
    data,
    onSuccess: slice.actions.checkUser.type,
    onFail: slice.actions.checkUser.type
});
export const saveBusiness = (data) => apiCall({
    url: '/business/create',
    method: 'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type,
});
export const editBusiness = (data) => apiCall({
    url: '/business/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editFrom.type,
    onFail: slice.actions.editFrom.type
});
export const changeBusinessMinus = (data) => apiCall({
    url: '/business/sale-minus/' + data,
    method: 'put',
    data,
    onSuccess: slice.actions.editFrom.type,
    onFail: slice.actions.editFrom.type
});
export const deleteBusiness = (data) => apiCall({
    url: '/business' / +data,
    method: 'delete',
    onSuccess: slice.actions.deleteFrom.type,
    onFail: slice.actions.deleteFrom.type
});


export default slice.reducer
