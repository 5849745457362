import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

export const slice = createSlice({
    name: 'users',
    initialState: {
        userInfo: null,
        userHistory:null,
        users: [],
        id: '',
        user: {},
        token: {
            token_name: ''
        },
        branches: [],
        branchesValues: [],
        roles: null,
        roleID: '',
        businessId: "",
        branchId: "",
        authEnter: false,
        error: false,
        rememberme: false,
        logout: false,


        //User Roles
        addUser: false,
        editUser: false,
        viewUser: false,
        viewUserAdmin: false,
        deleteUser: false,
        userEditProfile: false,

        addRole: false,
        editRole: false,
        viewRole: false,
        viewRoleAdmin: false,
        deleteRole: false,


        addBranch: false,
        editBranch: false,
        viewBranch: false,
        viewBranchAdmin: false,
        deleteBranch: false,

        addOutlay: false,
        editOutlay: false,
        viewOutlay: false,
        viewOutlayAdmin: false,
        deleteOutlay: false,

        addExchange: false,
        editExchange: false,
        viewExchange: false,
        viewExchangeAdmin: false,
        deleteExchange: false,

        addTrade: false,
        editTrade: false,
        viewTrade: false,
        viewMyTrade: false,
        viewTradeAdmin: false,
        deleteTrade: false,
        deleteMyTrade: false,

        addPurchase: false,
        editPurchase: false,
        viewPurchase: false,
        viewPurchaseAdmin: false,
        deletePurchase: false,

        addContent: false,
        editContent: false,
        viewContent: false,
        deleteContent: false,

        viewProduction: false,
        addProduction: false,

        addBrand: false,
        editBrand: false,
        viewBrand: false,
        deleteBrand: false,

        addCategory: false,
        editCategory: false,
        viewCategory: false,
        viewCategoryAdmin: false,
        deleteCategory: false,
        addChildCategory: false,

        addProductType: false,
        editProductType: false,
        viewProductType: false,
        deleteProductType: false,

        addProduct: false,
        editProduct: false,
        viewProduct: false,
        deleteProduct: false,
        viewProductAdmin: false,


        getExcel: false,
        postExcel: false,
        viewReport: false,

        addCustomerGroup: false,
        editCustomerGroup: false,
        viewCustomerGroup: false,
        deleteCustomerGroup: false,

        addSupplier: false,
        editSupplier: false,
        viewSupplier: false,
        deleteSupplier: false,
        viewSupplierAdmin: false,

        addCustomer: false,
        editCustomer: false,
        viewCustomer: false,
        deleteCustomer: false,
        viewCustomerAdmin: false,

        addBusiness: false,
        editBusiness: false,
        viewBusiness: false,
        deleteBusiness: false,

        addTariff: false,
        editTariff: false,
        viewTariff: false,
        deleteTariff: false,
        viewByTariff: false,


        addProjectTypeChecked: false,
        editProjectTypeChecked: false,
        getProjectTypeChecked: false,
        deleteProjectTypeChecked: false,

        addTaskStatusChecked: false,
        getTaskStatusChecked: false,
        editTaskStatusChecked: false,
        deleteTaskStatusChecked: false,

        addTaskTypeChecked: false,
        getTaskTypeChecked: false,
        editTaskTypeChecked: false,
        deleteTaskTypeChecked: false,

        addProjectChecked: false,
        getProjectChecked: false,
        getOwnProjectChecked: false,
        editProjectChecked: false,
        deleteProjectChecked: false,

        addTaskChecked: false,
        getTaskChecked: false,
        getOwnTaskChecked: false,
        editTaskChecked: false,
        deleteTaskChecked: false,

        addBonusChecked: false,
        getBonusChecked: false,
        editBonusChecked: false,
        deleteBonusChecked: false,

        AllPrizeRoles: false,
        addPrizeChecked: false,
        viewPrizeChecked: false,

        AllLessonRoles: false,
        addLessonChecked: false,
        viewLessonChecked: false,
        viewLessonRoleChecked: false,
        editLessonChecked: false,
        deleteLessonChecked: false,

        editInvoiceChecked: false,

        viewLidChecked: false,
        editLidChecked: false,
        deleteLidChecked: false,

        viewFormLidChecked: false,
        addFormLidChecked: false,
        editFormLidChecked: false,
        deleteFormLidChecked: false,

        viewLidStatusChecked: false,
        addLidStatusChecked: false,
        editLidStatusChecked: false,
        deleteLidStatusChecked: false,

        addJobChecked: false,
        editJobChecked: false,
        viewJobChecked: false,
        deleteJobChecked: false,

        viewOrgChecked: false,

        addWorkTimeChecked: false,
        viewAttendanceChecked: false,

        viewSalaryChecked:false,
        editSalaryChecked:false,

        viewInfoAdmin: false,
        viewInfo: false,

        viewDashboardChecked: false,

        viewMyBusiness: false,
        editMyBusiness: false,
        viewLoss: false,
        addLoss: false,
        viewNavigator: false,
        deleteNavigator: false,
        addNavigator: false,
    },
    reducers: {
        saveusers: (state, action) => {
            if (action.payload.success) {
                state.authEnter = true

                state.users = action.payload.object
                if (action.payload.object.business) {
                    state.businessId = action.payload.object.business.id
                }
                if(action.payload.object.branches){
                    state.branchId = action.payload.object.branches[0].id
                    state.branchesValues = action.payload.object.branches.map(({
                                                                                   name: label,
                                                                                   id: value, ...rest
                                                                               }) => ({label, value, ...rest}))
                    state.branches = action.payload.object.branches
                }
                state.roleID = action.payload.object.role?.id
                state.id = action.payload.object?.id
                if (state.rememberme === true) {
                    localStorage.setItem('user', JSON.stringify(action.payload.object))
                    localStorage.setItem('tokenname', action.payload.message)
                    localStorage.setItem('authLogin', state.authEnter)
                } else {
                    sessionStorage.setItem('user', JSON.stringify(action.payload.object))
                    sessionStorage.setItem('tokenname', action.payload.message)
                    sessionStorage.setItem('authLogin', state.authEnter)

                }
                state.logout = false
                state.roles = state.users.role.permissions
                state.users.role.permissions.map(item => {
                    switch (item) {
                        case "ADD_USER":
                            state.addUser = true
                            break;
                        case "EDIT_USER":
                            state.editUser = true
                            break;
                        case "VIEW_USER":
                            state.viewUser = true
                            break;
                        case "DELETE_USER":
                            state.deleteUser = true
                            break;
                        case "VIEW_USER_ADMIN":
                            state.viewUserAdmin = true
                            break;
                        case "EDIT_MY_PROFILE":
                            state.userEditProfile = true
                            break;

                        case "ADD_ROLE":
                            state.addRole = true
                            break;
                        case "EDIT_ROLE":
                            state.editRole = true
                            break;
                        case "VIEW_ROLE":
                            state.viewRole = true
                            break;
                        case "DELETE_ROLE":
                            state.deleteRole = true
                            break;
                        case "VIEW_ROLE_ADMIN":
                            state.viewRoleAdmin = true
                            break;
                        case "ADD_BRANCH":
                            state.addBranch = true
                            break;
                        case "EDIT_BRANCH":
                            state.editBranch = true
                            break;
                        case "VIEW_BRANCH_ADMIN":
                            state.viewBranch = true
                            break;
                        case "DELETE_BRANCH":
                            state.deleteBranch = true
                            break;
                        case "VIEW_BRANCH":
                            state.viewBranchAdmin = true
                            break;

                        case "ADD_OUTLAY":
                            state.addOutlay = true
                            break;
                        case "EDIT_OUTLAY":
                            state.editOutlay = true
                            break;
                        case "VIEW_OUTLAY":
                            state.viewOutlay = true
                            break;
                        case "DELETE_OUTLAY":
                            state.deleteOutlay = true
                            break;
                        case "VIEW_OUTLAY_ADMIN":
                            state.viewOutlayAdmin = true
                            break;


                        case "ADD_EXCHANGE":
                            state.addExchange = true
                            break;
                        case "EDIT_EXCHANGE":
                            state.editExchange = true
                            break;
                        case "VIEW_EXCHANGE":
                            state.viewExchange = true
                            break;
                        case "DELETE_EXCHANGE":
                            state.deleteExchange = true
                            break;
                        case "VIEW_EXCHANGE_ADMIN":
                            state.viewExchangeAdmin = true
                            break;


                        case "ADD_TRADE":
                            state.addTrade = true
                            break;
                        case "EDIT_TRADE":
                            state.editTrade = true
                            break;
                        case "VIEW_TRADE":
                            state.viewTrade = true
                            break;
                        case "VIEW_MY_TRADE":
                            state.viewMyTrade = true
                            break;
                        case "DELETE_TRADE":
                            state.deleteTrade = true
                            break;
                        case "DELETE_MY_TRADE":
                            state.deleteMyTrade = true
                            break;
                        case "VIEW_TRADE_ADMIN":
                            state.viewTradeAdmin = true
                            break;


                        case "ADD_PURCHASE":
                            state.addPurchase = true
                            break;
                        case "EDIT_PURCHASE":
                            state.editPurchase = true
                            break;
                        case "VIEW_PURCHASE":
                            state.viewPurchase = true
                            break;
                        case "DELETE_PURCHASE":
                            state.deletePurchase = true
                            break;
                        case "VIEW_PURCHASE_ADMIN":
                            state.viewPurchaseAdmin = true
                            break;

                        case "CREATE_CONTENT":
                            state.addContent = true
                            break;
                        case "EDIT_CONTENT":
                            state.editContent = true
                            break;
                        case "GET_CONTENT":
                            state.viewContent = true
                            break;
                        case "DELETE_CONTENT":
                            state.deleteContent = true
                            break;

                        case "GET_PRODUCTION":
                            state.viewProduction = true
                            break;
                        case "CREATE_PRODUCTION":
                            state.addProduction = true
                            break;

                        case "ADD_BRAND":
                            state.addBrand = true
                            break;
                        case "EDIT_BRAND":
                            state.editBrand = true
                            break;
                        case "VIEW_BRAND":
                            state.viewBrand = true
                            break;
                        case "DELETE_BRAND":
                            state.deleteBrand = true
                            break;

                        case "ADD_CATEGORY":
                            state.addCategory = true
                            break;
                        case "EDIT_CATEGORY":
                            state.editCategory = true
                            break;
                        case "VIEW_CATEGORY":
                            state.viewCategory = true
                            break;
                        case "VIEW_CATEGORY_ADMIN":
                            state.viewCategoryAdmin = true
                            break;
                        case "DELETE_CATEGORY":
                            state.deleteCategory = true
                            break;
                        case "ADD_CHILD_CATEGORY":
                            state.addChildCategory = true
                            break;

                        case "ADD_PRODUCT_TYPE":
                            state.addProductType = true
                            break;
                        case "UPDATE_PRODUCT_TYPE":
                            state.editProductType = true
                            break;
                        case "GET_PRODUCT_TYPE":
                            state.viewProductType = true
                            break;
                        case "DELETE_PRODUCT_TYPE":
                            state.deleteProductType = true
                            break;

                        case "ADD_PRODUCT":
                            state.addProduct = true
                            break;
                        case "EDIT_PRODUCT":
                            state.editProduct = true
                            break;
                        case "VIEW_PRODUCT":
                            state.viewProduct = true
                            break;
                        case "DELETE_PRODUCT":
                            state.deleteProduct = true
                            break;
                        case "VIEW_PRODUCT_ADMIN":
                            state.viewProductAdmin = true
                            break;

                        case "POST_EXCEL":
                            state.postExcel = true
                            break;

                        case "GET_EXCEL":
                            state.getExcel = true
                            break;


                        case "ADD_CUSTOMER_GROUP":
                            state.addCustomerGroup = true
                            break;
                        case "EDIT_CUSTOMER_GROUP":
                            state.editCustomerGroup = true
                            break;
                        case "VIEW_CUSTOMER_GROUP":
                            state.viewCustomerGroup = true
                            break;
                        case "DELETE_CUSTOMER_GROUP":
                            state.deleteCustomerGroup = true
                            break;

                        case "ADD_CUSTOMER":
                            state.addCustomer = true
                            break;
                        case "EDIT_CUSTOMER":
                            state.editCustomer = true
                            break;
                        case "VIEW_CUSTOMER":
                            state.viewCustomer = true
                            break;
                        case "DELETE_CUSTOMER":
                            state.deleteCustomer = true
                            break;
                        case "VIEW_CUSTOMER_ADMIN":
                            state.viewCustomerAdmin = true
                            break;

                        case "ADD_SUPPLIER":
                            state.addSupplier = true
                            break;
                        case "EDIT_SUPPLIER":
                            state.editSupplier = true
                            break;
                        case "VIEW_SUPPLIER":
                            state.viewSupplier = true
                            break;
                        case "DELETE_SUPPLIER":
                            state.deleteSupplier = true
                            break;
                        case "VIEW_SUPPLIER_ADMIN":
                            state.viewSupplierAdmin = true
                            break;

                        case "ADD_BUSINESS":
                            state.addBusiness = true
                            break;
                        case "EDIT_BUSINESS":
                            state.editBusiness = true
                            break;
                        case "VIEW_BUSINESS":
                            state.viewBusiness = true
                            break;
                        case "DELETE_BUSINESS":
                            state.deleteBusiness = true
                            break;

                        case "CREATE_TARIFF":
                            state.addTariff = true
                            break;
                        case "EDIT_TARIFF":
                            state.editTariff = true
                            break;
                        case "GET_TARIFF":
                            state.viewTariff = true
                            break;
                        case "DELETE_TARIFF":
                            state.deleteTariff = true
                            break;
                        case "GET_BY_TARIFF":
                            state.viewByTariff = true
                            break;
                        case "VIEW_REPORT":
                            state.viewReport = true
                            break;
                        case 'ADD_PROJECT_TYPE':
                            state.addProjectTypeChecked = true
                            break;
                        case 'GET_PROJECT_TYPE':
                            state.getProjectTypeChecked = true
                            break;
                        case 'EDIT_PROJECT_TYPE':
                            state.editProjectTypeChecked = true
                            break;
                        case 'DELETE_PROJECT_TYPE':
                            state.deleteProjectTypeChecked = true
                            break;
                        case 'ADD_TASK_STATUS':
                            state.addTaskStatusChecked = true
                            break;
                        case 'GET_TASK_STATUS':
                            state.getTaskStatusChecked = true
                            break;
                        case 'EDIT_TASK_STATUS':
                            state.editTaskStatusChecked = true
                            break;
                        case 'DELETE_TASK_STATUS':
                            state.deleteTaskStatusChecked = true
                            break;
                        case 'ADD_TASK_TYPE':
                            state.addTaskTypeChecked = true
                            break;
                        case 'GET_TASK_TYPE':
                            state.getTaskTypeChecked = true
                            break;
                        case 'EDIT_TASK_TYPE':
                            state.editTaskTypeChecked = true
                            break;
                        case 'DELETE_TASK_TYPE':
                            state.deleteTaskTypeChecked = true
                            break;
                        case 'ADD_PROJECT':
                            state.addProjectChecked = true
                            break;
                        case 'GET_PROJECT':
                            state.getProjectChecked = true
                            break;
                        case 'GET_OWN_PROJECT':
                            state.getOwnProjectChecked = true
                            break;
                        case 'EDIT_PROJECT':
                            state.editProjectChecked = true
                            break;
                        case 'DELETE_PROJECT':
                            state.deleteProjectChecked = true
                            break;
                        case 'ADD_TASK':
                            state.addTaskChecked = true
                            break;
                        case 'GET_TASK':
                            state.getTaskChecked = true
                            break;
                        case 'GET_OWN_TASK':
                            state.getOwnTaskChecked = true
                            break;
                        case 'EDIT_TASK':
                            state.editTaskChecked = true
                            break;
                        case 'DELETE_TASK':
                            state.deleteTaskChecked = true
                            break;
                        case 'ADD_BONUS':
                            state.addBonusChecked = true
                            break;
                        case 'GET_BONUS':
                            state.getBonusChecked = true
                            break;
                        case 'EDIT_BONUS':
                            state.editBonusChecked = true
                            break;
                        case 'DELETE_BONUS':
                            state.deleteBonusChecked = true
                            break;
                        case 'VIEW_PRIZE':
                            state.viewPrizeChecked = true
                            break;
                        case 'ADD_PRIZE':
                            state.addPrizeChecked = true
                            break;
                        case 'EDIT_INVOICE':
                            state.editInvoiceChecked = true
                            break;
                        case 'ADD_LESSON':
                            state.addLessonChecked = true
                            break;
                        case 'VIEW_LESSON':
                            state.viewLessonChecked = true
                            break;
                        case 'VIEW_LESSON_ROLE':
                            state.viewLessonRoleChecked = true
                            break;
                        case 'EDIT_LESSON':
                            state.editLessonChecked = true
                            break;
                        case 'DELETE_LESSON':
                            state.deleteLessonChecked = true
                            break;
                        case 'VIEW_LID':
                            state.viewLidChecked = true
                            break;
                        case 'EDIT_LID':
                            state.editLidChecked = true
                            break;
                        case 'DELETE_LID':
                            state.deleteLidChecked = true
                            break;
                        case 'ADD_FORM_LID':
                            state.addFormLidChecked = true
                            break;
                        case 'VIEW_FORM_LID':
                            state.viewFormLidChecked = true
                            break;
                        case 'EDIT_FORM_LID':
                            state.editFormLidChecked = true
                            break;
                        case 'DELETE_FORM_LID':
                            state.deleteFormLidChecked = true
                            break;
                        case 'ADD_LID_STATUS':
                            state.addLidStatusChecked = true
                            break;
                        case 'VIEW_LID_STATUS':
                            state.viewLidStatusChecked = true
                            break;
                        case 'EDIT_LID_STATUS':
                            state.editLidStatusChecked = true
                            break;
                        case 'DELETE_LID_STATUS':
                            state.deleteLidStatusChecked = true
                            break;
                        case 'ADD_JOB':
                            state.addJobChecked = true
                            break;
                        case 'VIEW_JOB':
                            state.viewJobChecked = true
                            break;
                        case 'EDIT_JOB':
                            state.editJobChecked = true
                            break;
                        case 'DELETE_JOB':
                            state.deleteJobChecked = true
                            break;
                        case 'VIEW_INFO_ADMIN':
                            state.viewInfoAdmin = true
                            break;
                        case 'VIEW_INFO':
                            state.viewInfo = true
                            break;
                        case 'ADD_WORK_TIME':
                            state.addWorkTimeChecked = true
                            break;
                        case 'GET_WORK_TIME':
                            state.viewAttendanceChecked = true
                            break;
                        case 'GET_SALARY':
                            state.viewSalaryChecked = true
                            break;
                        case 'EDIT_SALARY':
                            state.editSalaryChecked = true
                            break;
                        case "VIEW_ORG":
                            state.viewOrgChecked = true
                            break;
                        case "VIEW_DASHBOARD":
                            state.viewDashboardChecked = true
                            break;
                        case 'VIEW_MY_BUSINESS':
                            state.viewMyBusiness = true
                            break;
                        case 'EDIT_MY_BUSINESS':
                            state.editMyBusiness = true
                            break;
                        case 'VIEW_NAVIGATION':
                            state.viewNavigator = true
                            break;
                        case 'DELETE_NAVIGATION':
                            state.deleteNavigator = true
                            break;
                        case 'ADD_NAVIGATION':
                            state.addNavigator = true
                            break;
                        case 'ADD_LOSS':
                            state.addLoss = true
                            break;
                        case 'GET_LOSS':
                            state.viewLoss = true
                            break;
                    }
                })
            } else {
                state.error = true
                toast.info('Parol yoki login xato')
            }
        },
        get: (state, action) => {
            state.users = action.payload.object
            // state.user = action.payload.object
        },
        getUserInfo: (state, action) => {
            if (action.payload.success) {
                state.userInfo = action.payload.object
            }
        },
        getDoHistory: (state, action) => {
            if (action.payload.success) {
                state.userHistory = action.payload.object
            }
            else{
                state.userHistory = null
            }
        },
        editForm: () => {
            toast.success('Tahrirlandi')
        },
        changeerror: (state) => {
            state.error = false
        },
        savdooynasi: (state) => {
            state.linkheader = !state.linkheader
            state.linksavdooynasi = !state.linksavdooynasi
        },
        rememberMe: (state, action) => {
            state.rememberme = action.payload.checked
        },
        logOutUser: (state) => {
            state.users = []
            state.id = ''
            state.user = {}
            state.branches = []
            state.roles = null
            state.businessId = ""
            state.branchId = ""
            state.authEnter = false
            state.error = false
            state.rememberme = false
            state.logout = true


            //User Roles
            state.addUser = false
            state.editUser = false
            state.viewUser = false
            state.viewUserAdmin = false
            state.deleteUser = false
            state.userEditProfile = false
            state.addRole = false
            state.editRole = false
            state.viewRole = false
            state.viewRoleAdmin = false
            state.deleteRole = false
            state.addBranch = false
            state.editBranch = false
            state.viewBranch = false
            state.viewBranchAdmin = false
            state.deleteBranch = false
            state.addOutlay = false
            state.editOutlay = false
            state.viewOutlay = false
            state.viewOutlayAdmin = false
            state.deleteOutlay = false
            state.addExchange = false
            state.editExchange = false
            state.viewExchange = false
            state.viewExchangeAdmin = false
            state.deleteExchange = false
            state.addTrade = false
            state.editTrade = false
            state.viewTrade = false
            state.viewMyTrade = false
            state.viewTradeAdmin = false
            state.deleteTrade = false
            state.deleteMyTrade = false
            state.addPurchase = false
            state.editPurchase = false
            state.viewPurchase = false
            state.viewPurchaseAdmin = false
            state.deletePurchase = false
            state.addContent = false
            state.editContent = false
            state.viewContent = false
            state.deleteContent = false
            state.viewProduction = false
            state.addProduction = false
            state.addBrand = false
            state.editBrand = false
            state.viewBrand = false
            state.deleteBrand = false
            state.addCategory = false
            state.editCategory = false
            state.viewCategory = false
            state.viewCategoryAdmin = false
            state.deleteCategory = false
            state.addChildCategory = false
            state.addProductType = false
            state.editProductType = false
            state.viewProductType = false
            state.deleteProductType = false
            state.addProduct = false
            state.editProduct = false
            state.viewProduct = false
            state.deleteProduct = false
            state.viewProductAdmin = false
            state.getExcel = false
            state.postExcel = false
            state.viewReport = false
            state.addCustomerGroup = false
            state.editCustomerGroup = false
            state.viewCustomerGroup = false
            state.deleteCustomerGroup = false
            state.addSupplier = false
            state.editSupplier = false
            state.viewSupplier = false
            state.deleteSupplier = false
            state.viewSupplierAdmin = false
            state.addCustomer = false
            state.editCustomer = false
            state.viewCustomer = false
            state.deleteCustomer = false
            state.viewCustomerAdmin = false
            state.addBusiness = false
            state.editBusiness = false
            state.viewBusiness = false
            state.deleteBusiness = false
            state.addTariff = false
            state.editTariff = false
            state.viewTariff = false
            state.deleteTariff = false
            state.viewByTariff = false


            state.addProjectTypeChecked = false
            state.editProjectTypeChecked = false
            state.getProjectTypeChecked = false
            state.deleteProjectTypeChecked = false
            state.addTaskStatusChecked = false
            state.getTaskStatusChecked = false
            state.editTaskStatusChecked = false
            state.deleteTaskStatusChecked = false
            state.addTaskTypeChecked = false
            state.getTaskTypeChecked = false
            state.editTaskTypeChecked = false
            state.deleteTaskTypeChecked = false

            state.addProjectChecked = false
            state.getProjectChecked = false
            state.getOwnProjectChecked = false
            state.editProjectChecked = false
            state.deleteProjectChecked = false
            state.addTaskChecked = false
            state.getTaskChecked = false
            state.getOwnTaskChecked = false
            state.editTaskChecked = false
            state.deleteTaskChecked = false


            state.addBonusChecked = false
            state.getBonusChecked = false
            state.editBonusChecked = false
            state.deleteBonusChecked = false
            state.addPrizeChecked = false
            state.viewPrizeChecked = false


            state.addLessonChecked = false
            state.viewLessonChecked = false
            state.viewLessonRoleChecked = false
            state.editLessonChecked = false
            state.deleteLessonChecked = false

            state.editInvoiceChecked = false

            state.viewLidChecked = false
            state.editLidChecked = false
            state.deleteLidChecked = false
            state.viewFormLidChecked = false
            state.addFormLidChecked = false
            state.editFormLidChecked = false
            state.deleteFormLidChecked = false
            state.viewLidStatusChecked = false
            state.addLidStatusChecked = false
            state.editLidStatusChecked = false
            state.deleteLidStatusChecked = false

            state.addJobChecked = false
            state.editJobChecked = false
            state.viewJobChecked = false
            state.deleteJobChecked = false
            state.viewOrgChecked = false
            state.viewInfo = false
            state.viewInfoAdmin = false

            state.viewAttendanceChecked = false
            state.addWorkTimeChecked = false
            state.viewSalaryChecked = false
            state.editSalaryChecked = false

            state.viewDashboardChecked = false

        }
    }
})

export const getUser = (data) => apiCall({
    url: '/user/' + data,
    method: 'get',
    onSuccess: slice.actions.get.type
})

export const editUser = (data) => apiCall({
    url: '/user',
    method: 'put',
    data,
    onSuccess: slice.actions.editForm.type
})

export const userInfo = (data) => apiCall({
    url: '/user/get-by-patron/' + data,
    method: 'get',
    data,
    onSuccess: slice.actions.getUserInfo.type,
    onFail: slice.actions.getUserInfo.type
})
export const userDoHistory = (data) => apiCall({
    url: '/history/' + data.id,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getDoHistory.type,
    onFail: slice.actions.getDoHistory.type
})
export const {changeerror, savdooynasi, saveusers, rememberMe, logOutUser} = slice.actions
export default slice.reducer
