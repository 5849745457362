import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'salary',
    initialState: {
        salary:null,
        salaryUser:null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        deleteBoolean: false,
        message:'',
        userMonth:null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.salary = action.payload.object
            }
            else{
                state.salary = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getUserMonth: (state,action)=>{
            if (action.payload.success){
                state.userMonth = action.payload.object
            }else{
                state.userMonth = null
            }
        },
        getFromUser: (state, action) => {
            if (action.payload.success){
                state.salaryUser = action.payload.object
            }
            else{
                state.salaryUser = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editAvans: (state,action) => {
            if (action.payload.success){
                toast.success('Tahrirlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        editSalary: (state,action) => {
            if (action.payload.success){
                toast.success('maosh tahrirlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
                state.deleteBoolean=!state.deleteBoolean
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});


export const getSalaryByBranch=(data)=>apiCall({
    url: '/salary/by-branch/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getUserMonths=(data)=>apiCall({
    url: `/salary/by-user-last-month/${data.userId}?${data.branchId? `branchId=${data.branchId}`:''}`,
    method:'get',
    onSuccess: slice.actions.getUserMonth.type,
    onFail: slice.actions.getUserMonth.type,
});

export const getSalaryByUser=(data)=>apiCall({
    url: `/salary/by-user/${data.userId}?${data.branchId? `branchId=${data.branchId}`:''}`,
    method:'get',
    onSuccess: slice.actions.getFromUser.type,
    onFail: slice.actions.getFromUser.type,
});
export const editAvans=(data)=>apiCall({
    url: `/salary/pay-avans/`+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.editAvans.type,
    onFail: slice.actions.editAvans.type,
});
export const editSalary=(data)=>apiCall({
    url: `/salary/pay-salary/`+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.editSalary.type,
    onFail: slice.actions.editSalary.type,
});
export default slice.reducer