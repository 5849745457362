import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

export const slice=createSlice({
    name:'lossProduct',
    initialState:{
        lossProduct:null,
        oneLossProduct:null,
        current:false,
        saveBoolean:false,
        getBoolean:false,
        message:''
    },
    reducers:{
        get:(state,action)=>{
             if(action.payload.success){
                 state.lossProduct=action.payload.object
             }
             else{
                 state.lossProduct = null
                 state.message= action.payload.message
             }
             state.getBoolean = !state.getBoolean
            state.saveBoolean=false
        },
        getOne:(state,action)=>{
            if(action.payload.success){
                state.oneLossProduct=action.payload.object
            }
            else{
                state.oneLossProduct = null
                state.message= action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean=false
        },
        save:(state,action)=>{
            if (action.payload.success){
                toast.success('Success !')
                state.saveBoolean = true
            }
            else{
                toast.warning(action.payload.message)
            }
            state.current= !state.current

        }
    }
})

export const getLossProduct=(data)=>apiCall({
    url:'/loss/by-branch/'+data.branchId,
    method:'get',
    params:data.params,
    onSuccess:slice.actions.get.type,
    onFail:slice.actions.get.type
})
export const getLossProductOne=(data)=>apiCall({
    url:'/loss/'+data.id+"/"+data.branch,
    method:'get',
    onSuccess:slice.actions.getOne.type,
    onFail:slice.actions.getOne.type
})
export const saveLossProduct=(data)=>apiCall({
    url:'/loss',
    method:'post',
    data,
    onSuccess:slice.actions.save.type,
    onFail:slice.actions.save.type,
})


export default slice.reducer