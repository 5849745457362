import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'xaridxisobot',
    initialState: {
        xaridxisobot: null,
        topSuplier:null,
        xaridOne: null,
        xaridTwo: [],
        message:'',
        getTopSuplierBoolean:false,
        getBoolean: false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.xaridxisobot = action.payload.object
            }else{
                state.xaridxisobot = null
                    state.message  = action.payload.message
            }
            state.getBoolean = !state.getBoolean
        },
        getFromTopSuplier: (state, action) => {
            if (action.payload.success){
                state.topSuplier = action.payload.object
            }else{
                state.topSuplier = null
                    state.message  = action.payload.message
            }
            state.getTopSuplierBoolean = !state.getTopSuplierBoolean
        },
        getFrom2: (state, action) => {
            if (action.payload.success){
                let a = []
                a.push(action.payload.object?.purchase)
                state.xaridOne = a
                state.xaridTwo = action.payload.object?.purchaseProductList
            }else {
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
        }
    }
});

// export const getXaridXisobot=(data)=>apiCall({
//     url: '/reports/purchase/'+data,
//     method:'get',
//     onSuccess: slice.actions.getFrom.type,
//     onFail: slice.actions.getFrom.type
// });
export const getXaridXisobot=(data)=>apiCall({
    url: `/reports/purchase/${data.branchId}/?${data.supplier ? `supplierId=${data.supplier}`:''}${data.startDate ? `&startDate=${data.startDate}`:''}${data.endDate ? `&endDate=${data.endDate}`:''}`,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const getTopSuplier=(data)=>apiCall({
    url: `/reports/get-top-supplier/${data.branchId}`,
    method:'get',
    params: {endDate:data.endDate,startDate: data.startDate},
    onSuccess: slice.actions.getFromTopSuplier.type,
    onFail: slice.actions.getFromTopSuplier.type
});

// export const getXaridXisobot3=(data)=>apiCall({
//     url: `/reports/purchase/`+data,
//     method:'get',
//     onSuccess: slice.actions.getFrom.type,
//     onFail: slice.actions.getFrom.type
// });
// export const getXaridXisobot2=(data)=>apiCall({
//     url: `/reports/purchase/${data.branchId}/?from=${data.startDate}&to=${data.endDate}`,
//     method:'get',
//     onSuccess: slice.actions.getFrom.type,
//     onFail: slice.actions.getFrom.type
// });

export const getOneXaridXisobot=(data)=>apiCall({
    url: '/purchase/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail: slice.actions.getFrom2.type
});

export default slice.reducer