import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

export const slice = createSlice({
    name: 'notification',
    initialState: {
        notification: null,
        OneNotification: null,
        current: false,
        message: '',
        saveBoolean: false,
        saveSmsBoolean: false,
        notificationCount: 0,
        totalPageCount: 0,
    },
    reducers: {
        get: (state, action) => {
            if (action.payload.success) {
                state.notification = action.payload.object.notificationGetAllDts
                state.notificationCount = action.payload.object.notificationCount
                state.totalPageCount = action.payload.object.totalPageCount
            } else {
                state.notification = null
                state.message = action.payload.message
            }

        },
        plusCount: (state, action) => {
            state.sendNextPages += 5
        },
        getOne: (state, action) => {
            if (action.payload.success) {
                state.current = !state.current
                state.OneNotification = action.payload.object
            } else {
                state.current = !state.current
                state.OneNotification = null
                state.message = action.payload.message
            }
        },
        saveNotifications:(state,action)=>{
            if (action.payload.success){
                toast.success('XABAR JO`NATILDI')
                state.saveBoolean = true
            }  else {
                toast.success(action.payload.message)
            }
            state.current=!state.current
        },
        saveSms:(state,action)=>{
            if (action.payload.success){
                toast.success('SMS JO`NATILDI')
                state.saveSmsBoolean = true
            } else {
               toast.success(action.payload.message)
            }
        },
        saveWebSocket:(state,action)=>{
            if (action.payload.success){
                toast.success('SAVED')
            }  else {
                toast.error('ERROR')
            }
        },
        deleteAll: (state, action) => {
            if (action.payload.success) {
                state.current = !state.current

            } else {
                state.current = !state.current
                state.message = action.payload.message
            }
        }
    }
})

export const getNotification = (size = 5) => apiCall({
    url: '/notification?size='+size,
    method: 'get',
    onSuccess: slice.actions.get.type,
    onFail: slice.actions.get.type
})

export const getNotificationById = (id) => apiCall({
    url: '/notification/' + id,
    method: 'get',
    onSuccess: slice.actions.getOne.type,
    onFail: slice.actions.getOne.type
})
export const saveNotification = (data) => apiCall({
    url: '/notification',
    method: 'post',
    data,
    onSuccess: slice.actions.saveNotifications.type,
    onFail: slice.actions.saveNotifications.type
})
export const sendSms = (data) => apiCall({
    url: '/sms',
    method: 'post',
    data,
    onSuccess: slice.actions.saveSms.type,
    onFail: slice.actions.saveSms.type
})
export const sendWebSocket = (data) => apiCall({
    url: '/send',
    method: 'post',
    data,
    onSuccess: slice.actions.saveWebSocket.type,
    onFail: slice.actions.saveWebSocket.type
})

export const deleteNotification = (id) => apiCall({
    url: '/notification',
    method: 'delete',
    onSuccess: slice.actions.deleteAll.type,
    onFail: slice.actions.deleteAll.type
})

export default slice.reducer