import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";

const slice = createSlice({
    name: 'accounts',
    initialState:{
        Purchases: {},
        Pays:{},
        Outlay: {},
        Trade:{},
        time:[],
        PurchaseDate:{},
        PurchaseList:{},
        PurchaseCount:{},
        OutlayCategory : null,
        CustomerDebt:null
    },
    reducers: {
        getFrom:(state,action)=>{
            state.Purchases = action.payload.object
            state.Pays = action.payload.object?.byPayMethods
            state.OutlayCategory = action.payload.object?.outlayByCategory
            state.CustomerDebt = action.payload.object?.customerList
        },
        getFromPay:(state,action)=>{
            state.Pays = action.payload.object?.byPayMethods
        },
        getFromOutlay:(state,action)=>{
            state.Outlay = action.payload.object?.outlay
            state.Trade = action.payload.object?.trade
            state.time = action.payload.object?.timestampList
            state.PurchaseDate = action.payload.object.purchaseDateList
            state.PurchaseList = action.payload.object.purchasePriceList
            state.PurchaseCount = action.payload.object.purchaseCountList
        },
    }
})

export const getAccount=(data)=>apiCall({
    url: '/info/get-info-by-branch/'+data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getAccountAll=(data)=>apiCall({
    url: `/info/get-info-by-branch/${data.branchId}/?date=${data.date}`,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getAccountAllBusiness=(data)=>apiCall({
    url: `/info/get-info-by-business/${data.businessId}/?date=${data.date}`,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getAccountDate=(data)=>apiCall({
    url: `/info/get-info-by-branch/${data.branchId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getAccountDateBusiness=(data)=>apiCall({
    url: `/info/get-info-by-business/${data.businessId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getPay=(data)=>apiCall({
    url: '/info/get-info-by-branch/'+data,
    method: 'get',
    onSuccess: slice.actions.getFromPay.type,
    onFail: slice.actions.getFromPay.type
})
export const getOutlayTrade=(data)=>apiCall({
    url: '/info/get-info-by-outlay-trade/'+data,
    method: 'get',
    onSuccess: slice.actions.getFromOutlay.type,
    onFail: slice.actions.getFromOutlay.type
})
export const getOutlayTradeBusiness=(data)=>apiCall({
    url: `/info/get-info-by-outlay-trade-business/${data}`,
    method: 'get',
    onSuccess: slice.actions.getFromOutlay.type,
    onFail: slice.actions.getFromOutlay.type
})

export default slice.reducer