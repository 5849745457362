import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'lavozimlar',
    initialState: {
        lavozimlar: null,
        current: false,
        addRole: false,
        permissionList: [],
        message: '',
        getBoolean: false,
        saveRoleBool: false,
        deleteBoolean: false,
        deleteCurrent: false,

        checkLidRoles: false,
        checkTaskRoles: false,
        checkOrgRoles: false,
        checkLessonRoles: false,
        checkPrizeRoles: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.lavozimlar = action.payload.object
            } else {
                state.lavozimlar = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveRoleBool = false
            state.deleteCurrent = false
        },
        getAllPermissions: (state, action) => {
            if (action.payload.success) {
                state.permissionList = action.payload.object
                state.permissionList?.map(item => {
                    switch (item) {
                        case 'ADD_TASK':
                            state.checkTaskRoles = true
                            break;
                        case 'VIEW_LID':
                            state.checkLidRoles = true
                            break;
                        case 'VIEW_ORG':
                            state.checkOrgRoles = true
                            break;
                        case 'VIEW_LESSON':
                            state.checkLessonRoles = true
                            break;
                        case 'VIEW_PRIZE':
                            state.checkPrizeRoles = true
                            break;

                    }
                })
            } else {
                state.permissionList = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Lavozim qo`shildi')
                state.addRole = true
                state.saveRoleBool = true

            } else {
                toast.warning(action.payload.message)
                state.addRole = false
            }
            state.current = !state.current

        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Lavozim tahrirlandi')
                state.saveRoleBool = true
            } else {
                toast.warning(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Lavozim o\'chirildi')
                state.deleteBoolean = !state.deleteBoolean
            } else {
                toast.warning(action.payload.message)
            }
            state.deleteCurrent = !state.deleteCurrent
        }
    }
});

export const getLavozim = (data) => apiCall({
    url: '/role/get-by-business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getAllPermission = (data) => apiCall({
    url: '/role/get-role-permissions/' + data,
    method: 'get',
    onSuccess: slice.actions.getAllPermissions.type,
    onFail: slice.actions.getAllPermissions.type,
});

export const saveLavozim = (data) => apiCall({
    url: '/role',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editLavozim = (data) => apiCall({
    url: '/role/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteLavozim = (data) => apiCall({
    url: '/role/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer