import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'otkazmaxisobot',
    initialState: {
        otkazmaxisobot: []
    },
    reducers: {
        getFrom: (state, action) => {
            state.otkazmaxisobot = action.payload.object
        },
    }
});

export const getOtkazmalarxisoboti=(data)=>apiCall({
    url: '/exchange-product-branch/get-by-businessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export default slice.reducer
