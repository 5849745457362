import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'otkazmalar',
    initialState: {
        otkazmalar: null,
        otkazmaOne: [],
        counter: false,
        message: '',
        getExchangeBool: false,
        saveExchangeBool:false,
        Search: [],
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.otkazmalar = action.payload.object
            } else {
                state.otkazmalar = null
                state.message = action.payload.message

            }
            state.getExchangeBool = !state.getExchangeBool
            state.saveExchangeBool = false
        },
        getSearch: (state, action) => {
            if (action.payload.success){
                state.Search = action.payload.object
            }
            else{
                state.Search = null
                state.message = action.payload.message
                toast.error(`${action.payload.message} PRODUCT IN THIS BRANCH`)
            }

        },
        getFromOne: (state, action) => {
            state.otkazmaOne = action.payload.object
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma saqlandi')
                state.counter = !state.counter
                    state.saveExchangeBool = true
            } else {
                toast.error(action.payload.message)
            }
        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma tahrirlandi')
                state.counter = !state.counter
                state.saveExchangeBool = true
            } else {
                toast.error(action.payload.message)
            }
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma o\'chirildi')
                state.counter = !state.counter
            } else {
                toast.error(action.payload.message)
            }

        }
    }
});

export const getOtkazma = (data) => apiCall({
    url: '/exchange-product-branch/get-by-businessId/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getSearchBranch = (data) => apiCall({
    url: '/product/get-by-branch-for-purchase-trade/' + data,
    method: 'get',
    onSuccess: slice.actions.getSearch.type,
    onFail: slice.actions.getSearch.type
});


export const getOtkazmaByBranch = (data) => apiCall({
    url: '/exchange-product-branch/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type
});

export const getOneExchange = (data) => apiCall({
    url: '/exchange-product-branch/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
})
export const saveOtkazma = (data) => apiCall({
    url: '/exchange-product-branch',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail:slice.actions.savefrom.type
});

export const editOtkazma = (data) => apiCall({
    url: '/exchange-product-branch',
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteOtkazma = (data) => apiCall({
    url: '/exchange-product-branch/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer
