import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'lidMessenger',
    initialState: {
        lidMessenger: null,
        lidMessengerSelect:null,
        current:false,
        message:'',
        lidOneMessenger: null,
        getBoolean: false,
        saveBoolean: false,
        deleteBoolean: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if(action.payload.success){
                state.lidMessenger = action.payload.object
                let a = action.payload.object.map(({
                                               name:label,
                                               id:value, ...rest
                                           }) => ({label, value, ...rest}));
                state.lidMessengerSelect = a
            }
            else{
                state.message = action.payload.message
                state.lidMessenger = null
            }
            state.getBoolean = true
            state.saveBoolean = false
        },
        getFromLidOne:(state,action)=>{
            if (action.payload.success){
                state.lidOneMessenger=action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('ADDED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },

        editfrom: (state,action) => {

            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
                state.current=!state.current
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
});

export const getLidMessenger=(data)=>apiCall({
    url: '/source/byBusinessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getOneMessenger=(data)=>apiCall({
    url: '/source/'+data,
    method:'get',
    onSuccess: slice.actions.getFromLidOne.type,
    onFail: slice.actions.getFromLidOne.type,
});

export const saveLidMessenger=(data)=>apiCall({
    url: '/source',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editLidMessenger=(data)=>apiCall({
    url: '/source/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteLidMessenger=(data)=>apiCall({
    url: '/source/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer
