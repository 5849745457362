import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
import step_area from "canvasjs/src/charts/step_area";

const slice = createSlice({
    name: 'ichkibolim',
    initialState: {
        ichkibolim: [],
        childCategory:null,
        message:'',
        getBoolean:false,
        saveBoolean:false,
        current: false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.ichkibolim = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.ichkibolim = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFromID: (state, action) => {
            if (action.payload.success){
                state.childCategory = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.childCategory = null
            }
        },
        savefrom: (state,action) => {
            if(action.payload.success){
                toast.success("Qo'shildi")
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        editfrom: (state,action) => {
            if(action.payload.success){
                toast.success("Taxrirlandi")
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success("O\'chirildi")
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        }

    }
});

export const getichki=(data)=>apiCall({
    url: '/category/get-child-category/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const getChildCategory=(data)=>apiCall({
    url: '/category/get-child-category-by-parentId/'+data,
    method:'get',
    onSuccess: slice.actions.getFromID.type,
    onFail: slice.actions.getFromID.type
});
export const saveichkibolim=(data)=>apiCall({
    url: '/category',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const deleteichkibolim=(data)=>apiCall({
    url: '/category/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer