import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'cars',
    initialState: {
        cars:null,
        carOne:null,
        counter: false,
        current:false,
        message: '',
        getBoolean: false,
        saveBoolean:false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.cars = action.payload.object
            } else {
                state.cars = null
                state.message = action.payload.message

            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },

        getFromOne: (state, action) => {
            state.carOne = action.payload.object
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Mashina saqlandi')
                state.current = !state.current
                    state.saveBoolean = true
            } else {
                toast.error(action.payload.message)
            }
        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Mashina tahrirlandi')
                state.current = !state.current
                state.saveBoolean = true
            } else {
                toast.error(action.payload.message)
            }
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Mashina o\'chirildi')
                state.counter = !state.counter
            } else {
                toast.error(action.payload.message)
            }

        }
    }
});

export const getCars = (data) => apiCall({
    url: '/car/get-by-business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getOneCar = (data) => apiCall({
    url: '/car/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
})
export const saveCar = (data) => apiCall({
    url: '/car',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail:slice.actions.savefrom.type
});

export const editCar = (data) => apiCall({
    url: '/car/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteCar = (data) => apiCall({
    url: '/car/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer
