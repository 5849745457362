import {createSlice} from '@reduxjs/toolkit'
import {apiCall} from "../../../../../../api";
import {toast} from 'react-toastify'

const slice = createSlice({
    name: 'forms',
    initialState:{
        forms: null,
        formId: null,
        formSource: null,
        formField:[],
        current: false,
        message: '',
        getBoolean: false,
        saveBoolean: false,
        oneList: null,
        table:null
    },
    reducers:{
        getFrom: (state,action)=>{
            if (action.payload.success){
                state.forms = action.payload.object
                let a = []
                action.payload.object.map(item=>{
                    a.push({value:item?.sourceDto?.id,label:item?.sourceDto?.name})
                    state.formField = item?.lidFieldDtos
                    item?.lidFieldDtos.map(lid=>{
                        if (lid.valueType === 'SELECT'){
                            getLidSelectById(item.id)
                        }
                    })

                })
                state.formSource  = a
            }else {
                state.forms = null
                state.formField = []
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean

        },
        getOneFrom: (state,action)=>{
            if (action.payload.success){
                state.oneList = action.payload.object?.lidFieldDtos
            }else {

                state.message = action.payload.message
            }
        },
        getOneFromSelect: (state,action)=>{
            if (action.payload.success){
                state.oneList = action.payload.object?.lidFieldDtos
            }else {

                state.message = action.payload.message
            }
        },
        getFormTable:(state,action)=>{
            if (action.payload.success){
                state.table = action.payload.object
            }

        },
        saveFrom: (state,action)=>{
            if (action.payload.success){
                toast.success('ADDED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editForm:(state,action)=>{
            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deleteFrom: (state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
                state.current=!state.current
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
})

export const getForm =(data)=>apiCall({
    url: '/form-lid/getByBusinessId/'+data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getLidSelectById =(data)=>apiCall({
    url: '/select-for-lid/getByLidId/'+data,
    method: 'get',
    onSuccess: slice.actions.getOneFromSelect.type,
    onFail: slice.actions.getOneFromSelect.type
})
export const getLidFormTable =(data)=>apiCall({
    url: '/form-lid/getFormLidHistory/'+data,
    method: 'get',
    onSuccess: slice.actions.getFormTable.type,
    onFail: slice.actions.getFormTable.type
})
export const getOneForm =(data)=>apiCall({
    url: '/form-lid/get-form/'+data,
    method: 'get',
    onSuccess: slice.actions.getOneFrom.type,
    onFail: slice.actions.getOneFrom.type
})
export const saveForm =(data)=>apiCall({
    url: '/form-lid',
    method: 'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type
})

export const editForm =(data)=>apiCall({
    url: '/form-lid',
    method: 'put',
    data,
    onSuccess: slice.actions.editForm.type,
    onFail: slice.actions.editForm.type
})

export const deleteForm =(data)=>apiCall({
    url: '/form-lid/'+data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deleteFrom.type,
    onFail: slice.actions.deleteFrom.type
})
export default slice.reducer
