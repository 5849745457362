import {createSlice} from '@reduxjs/toolkit'
import {apiCall} from "../../../../../../api";
import {toast} from 'react-toastify'

const slice = createSlice({
    name: 'selectLids',
    initialState:{
        lids: null,
        message: '',
        getBoolean: false,
        saveBoolean: false,
        saveSelect: false,
        deleteBoolean: false,
        oneList: null,
        current: false,
        lidSelect: null,
        pageCurrent: false,
        currentGet: false
    },
    reducers:{
        getFrom: (state,action)=>{
            if (action.payload.success){
                state.lids = action.payload.object
                state.lidsTable =action.payload.object.getLessProduct

            }else {
                state.lids = null
                state.lidsTable = null
                state.message = action.payload.message
            }
            state.current=false
            state.saveBoolean = false
            state.getBoolean = !state.getBoolean
        },
        getFromPage: (state,action)=>{
            if (action.payload.success){
                state.lidSelect = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.pageCurrent=!state.pageCurrent

        },
        getOneFrom: (state,action)=>{
            if (action.payload.success){
                state.oneList = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.currentGet=!state.currentGet
        },
        saveFrom: (state,action)=>{
            if (action.payload.success){
                toast.success('ADDED')
                state.saveSelect = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editForm:(state,action)=>{
            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deleteFrom: (state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
                state.current=!state.current
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
})
export const getLidSelect =(data)=>apiCall({
    url: `/select-for-lid/getByBusinessId/`+data,
    params:data.params,
    method: 'get',
    onSuccess: slice.actions.getFromPage.type,
    onFail: slice.actions.getFromPage.type
})


export const getLidSelectById =(data)=>apiCall({
    url: '/select-for-lid/getByLidId/'+data,
    method: 'get',
    onSuccess: slice.actions.getOneFrom.type,
    onFail: slice.actions.getOneFrom.type
})
export const saveLidSelect =(data)=>apiCall({
    url: '/select-for-lid',
    method: 'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type
})

export const editLidSelect =(data)=>apiCall({
    url: `/select-for-lid/`+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editForm.type,
    onFail: slice.actions.editForm.type
})

export const deleteLidSelect =(data)=>apiCall({
    url: '/select-for-lid/'+data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deleteFrom.type,
    onFail: slice.actions.deleteFrom.type
})
export default slice.reducer