import React, {lazy, useEffect, useState} from "react";
import {Route, Switch, Redirect, useLocation, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import users,{saveusers} from "./reducer/users"
import '../src/dashboard/css/style.css'
import './App.css'
import Handle from "./components/SotibOlish/Sidebar/Lidlar/LidForms/Handle";
import { Helmet } from 'react-helmet';

import Navigator1 from "./components/SotibOlish/Sidebar/BiznesNavigator/navigator1/Navigator1";
const Home = lazy(() => import("./components/Hbody/Home"))
const Headerthird = lazy(() => import("./components/SotibOlish/headerthird"))
const SavdoOynasi = lazy(() => import("./components/SotibOlish/Sidebar/Savdo/SavdoOynasi/SavdoOynasi"))
const SecondPage = lazy(() => import("./components/Pricing/SecondPage/SecondPage"))
const ShopInfo = lazy(() => import("./components/Pricing/ShopInfo/ShopInfo"))
const TaxInfo = lazy(() => import("./components/Pricing/ShopInfo/TaxInfo"))
const UserDetails = lazy(() => import("./components/Pricing/ShopInfo/UserDetails"))
const PaymentDetails = lazy(() => import("./components/Pricing/ShopInfo/PaymentDetails"))
const Error500 = lazy(() => import("./dashboard/jsx/pages/Error500"))
const LidFormLink = lazy(() => import("./components/Pricing/LidFormLink/LidFormLink"))

function App({users,saveusers}) {



    const [auth,setAuth] = useState(false)
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const history = useHistory()
    const  location = useLocation();

    useEffect(()=>{
        setAuth(users.authEnter)
        let navLink= localStorage.getItem('navLik') || sessionStorage.getItem('navLik')
        if(location.pathname === '/headerthird'|| location.pathname === '/login' || location.pathname === '/'){
            if (location.pathname === '/login'){
              history.push('/headerthird')
            }
            else{
                if (users.rememberme){
                    localStorage.setItem("navLik",'')
                }
                else{
                    sessionStorage.setItem('navLik','')
                }
            }
            }
        else {
            if (!users.logout){
                if (users.rememberme){
                    localStorage.setItem("navLik",location.pathname)
                }
                else{
                    sessionStorage.setItem('navLik',location.pathname)
                }
            } else {
                if (users.rememberme){
                    localStorage.setItem("navLik","/headerthird")
                }
                else{
                    sessionStorage.setItem('navLik',"/headerthird")
                }
            }

        }
        users.viewDashboardChecked ? navLink && history.push(navLink) : navLink === "/headerthird" ? history.push("/headerthird/profil") : navLink && history.push(navLink)
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            toast.info(navigator.onLine ? 'Become Online':'Become Offline')
        };
        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    },[users.authEnter, isOnline])


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'))
        let tokenname = localStorage.getItem('tokenname') || sessionStorage.getItem('tokenname')
        if (user && tokenname){
            saveusers({
                object:user,
                message: tokenname,
                success: true
            })
            history.push( '/headerthird')
        }
    }, [])
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         console.clear()
    //     }, 5000);
    //
    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, []);
    return (
        <div>
            <div>
                <Helmet>
                    <meta name="referrer" content="origin" />
                </Helmet>
                <Switch>
                    <Route path={'/login'} component={Home}/>
                    <Route path={'/form-lid/get-form/:businessId/:sourceId'} component={LidFormLink}/>
                    <Route path={`/add/:sourceId`} component={Handle}/>
                    <Route path={'/shopDetails'} component={ShopInfo}/>
                    <Route path={'/taxDetails'} component={TaxInfo}/>
                    <Route path={'/userDetails'} component={UserDetails}/>
                    <Route path={'/paymentDetails'} component={PaymentDetails}/>
                    <Route path={'/success'} component={Error500}/>
                    <Route path={'/tariffs'} component={SecondPage}/>
                    {
                        auth ? <Route path={'/'}>
                            <Route path={'/headerthird'} component={Headerthird}/>
                            <Route path={'/turliTavar/:id'} exact component={SavdoOynasi}/>
                            <Route path={'/mahsulotQaytarish/:id/:remainId'} exact component={SavdoOynasi}/>
                            <Route path={'/turliTavar'} exact component={SavdoOynasi}/>
                            <Route path={'/navigator'} component={Navigator1}/>
                        </Route> : <Redirect to={'/login'}/>
                    }
                </Switch>
            </div>
        </div>

    );
}

export default connect((users), {saveusers}) (App);
