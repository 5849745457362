import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'content',
    initialState: {
        content: null,
        contentPage:null,
        oneContent: null,
        contentProductList: null,
        costGetDtoList:null,
        current: false,
        message:'',
        getBoolean:false,
        saveBoolean: false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.content = action.payload.object
            }else{
                state.content = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFromPage: (state, action) => {
            if (action.payload.success){
                state.contentPage = action.payload.object
            }else{
                state.contentPage = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFromById: (state, action) => {
            if (action.payload.success){
                state.oneContent = action.payload.object.content
                state.contentProductList = action.payload.object.contentProductList
                state.costGetDtoList = action.payload.object.costGetDtoList
            }else {
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.current = !state.current
            state.saveBoolean=false
        },
        savefrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean = true
                toast.success('Saqlandi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        editfrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean=true
                toast.success('Tahrirlandi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success){
                toast.success('Ro\'yhatdan o`chirildi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current

        }
    }
});

export const getContent = (id) => apiCall({
    url: '/content/by-branch/' + id,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const getContentByPage = (data) => apiCall({
    url: '/content/by-branch-pageable/' + data.branchId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFromPage.type,
    onFail:slice.actions.getFromPage.type
});
export const getContentById = (data) => apiCall({
    url: '/content/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromById.type
});

export const saveContent = (data) => apiCall({
    url: '/content',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});

export const editContent = (data) => apiCall({
    url: '/content/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});

export const deleteContent = (id) => apiCall({
    url: '/content/' + id,
    method: 'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
});

export default slice.reducer