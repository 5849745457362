import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'subscrip',
    initialState: {
        subscrip: [],
        current: false,
        onebusiness:[]
    },
    reducers: {
        getFrom: (state, action) => {
            state.subscrip = action.payload.object
        },
        getFrom2: (state, action) => {
            state.onebusiness = action.payload.object
        },
        saveFrom:(state,action)=>{
            state.current=!state.current
            toast.success(`Qo'shildi`)
        },
        editFrom:(state,action)=>{
            state.current=!state.current
            toast.success(`Tahrirlandi`)
        },
        deleteFrom:(state,action)=>{
            state.current=!state.current
            toast.error(`O'chirildi`)
        }
    }

});

export const getAllSubscrip=()=>apiCall({
    url: '/subscription/getAll',
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getOneBusiness=(data)=>apiCall({
    url: '/business/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail: slice.actions.getFrom2.type
});
export const saveSubscrip=(data)=>apiCall({
    url: '/subscription',
    method:'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type
});
export const editSubscrip=(data)=>apiCall({
    url: '/subscription/confirm/'+data.subscriptionId,
    method:'put',
    data:data.holat,
    onSuccess: slice.actions.editFrom.type,
    onFail: slice.actions.editFrom.type
});
export const deleteSubscrip=(data)=>apiCall({
    url: '/subscription'/+data,
    method:'delete',
    onSuccess: slice.actions.deleteFrom.type,
    onFail: slice.actions.deleteFrom.type
});


export default slice.reducer
