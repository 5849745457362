import React, {useEffect, useState} from 'react'
import './navigator1.css'
import {connect} from "react-redux";
import NavigatorReducer, {
    getNavigator,
    saveNavigator,
    getNavigatorAverage,
    getNavigatorProcess,
    deleteNavigator,
    getNavigatorProcessUpdate
} from "../reducer/NavigatorReducer";
import users from "../../../../../reducer/users";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useForm} from "react-hook-form";
import formatDate, {formatDateMinus, formatDateWithMonth, formatDateWithWeek} from "../../../../../util";
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    Button,
    Tabs,
    Tab,
    Avatar,
    Box,
    styled,
    useTheme
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Chart from 'react-apexcharts';


function Navigator1({
                        NavigatorReducer,
                        getNavigator,
                        users,
                        saveNavigator,
                        getNavigatorAverage,
                        getNavigatorProcess,
                        deleteNavigator,
                        getNavigatorProcessUpdate
                    }) {

    const [mainBranchId, setMainBranchId] = useState(null)


    const [month, setMonth] = useState(null)


    useEffect(() => {
        getNavigator(mainBranchId ? mainBranchId : users.branchId)
        getNavigatorAverage(mainBranchId ? mainBranchId : users.branchId)

        let day = new Date()
        let inputValue = `${day.getFullYear()}-${day.getMonth() + 1 > 10 ? day.getMonth() + 1 : `0${day.getMonth() + 1}`}`
        setMonth(inputValue)
        getDaysInMonth()
        getNavigatorProcess({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            params: {
                month: day.getMonth() + 1,
                year: day.getFullYear()
            }
        })
    }, [mainBranchId, NavigatorReducer.current])


    function getMonth(e) {
        setMonth(e.target.value)
        let day = new Date(e.target.value)
        getNavigatorProcess({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            params: {
                month: day.getMonth() + 1,
                year: day.getFullYear()
            }
        })
        const year = new Date(e.target.value).getFullYear()
        const month = new Date(e.target.value).getMonth()
        let date = new Date(year, month, 1)
        let days = [];
        let daysTime = []
        while (date.getMonth() === month) {
            days.push(`${formatDateWithMonth(new Date(date))}`);
            daysTime.push((new Date(date).getMilliseconds()) / 10000000);
            date.setDate(date.getDate() + 1);
        }
        setDays(days)
        setDaysTime(daysTime)
    }


    const [days, setDays] = useState([])
    const [daysTime, setDaysTime] = useState([])


    function getDaysInMonth() {
        const year = new Date().getFullYear()
        const month = new Date().getMonth()
        let date = new Date(year, month, 1)
        let days = [];
        let daysTime = []
        while (date.getMonth() === month) {
            days.push(`${formatDateWithMonth(new Date(date))}`);
            daysTime.push((new Date(date).getMilliseconds()) / 10000000);
            date.setDate(date.getDate() + 1);
        }
        setDays(days)
        setDaysTime(daysTime)
    }

    const {reset, register, setValue, formState: {errors}, handleSubmit} = useForm()

    const [navigationModal, setNavigationModal] = useState(false)

    function onSubmit(data) {
        saveNavigator({
            ...data,
            branchId: mainBranchId ? mainBranchId : users.branchId,
            initial: NavigatorReducer?.averageSum,
            startDate: new Date().getDay()
        })
    }

    function navigationToggle() {
        setNavigationModal(!navigationModal)
    }

    function changeBranch(e) {
        setMainBranchId(e.target.value)

    }

    const {t} = useTranslation();
    const theme = useTheme();


    const [listAverageSell, setAverageSell] = useState([])
    const [listAverageSellReal, setAverageSellReal] = useState([])
    const [maxValue, setMaxValue] = useState(0)
    const [minValue, setMinValue] = useState(0)

    useEffect(() => {
        if (NavigatorReducer.navigatorProcess) {
            let averageSellPlan = []
            let averageSellReal = []
            let allAverageSell = []
            let listLength = NavigatorReducer?.navigatorProcess?.goalList.length
            NavigatorReducer?.navigatorProcess?.goalList.map((item, index) => {
                averageSellPlan.push(item.totalSell)
                allAverageSell.push(item.totalSell)
            })
            NavigatorReducer?.navigatorProcess?.realList.map((item, index) => {
                averageSellReal.push(item.totalSell)
                allAverageSell.push(item.totalSell)
            })

            let minNumberDefault = Math.min(...allAverageSell)
            let maxNumberDefault = Math.max(...allAverageSell)
            let tenSum = (maxNumberDefault).toString()
            let maxRound = (tenSum.length / 2).toFixed(0)
            let maxNumber = tenSum.substring(0, maxRound)
            let finalMaxNumber = (parseInt(maxNumber) + 1) * (Math.pow(10, tenSum.length - maxRound))
            let tenSumMin = (minNumberDefault).toString()
            let minRound = (tenSumMin.length / 2).toFixed(0)
            let minNumber = tenSumMin.substring(0, minRound)
            let finalMinNumber = (parseInt(minNumber)) * (Math.pow(10, tenSumMin.length - minRound))
            setMinValue(finalMinNumber)
            setMaxValue(finalMaxNumber)

            setAverageSell(averageSellPlan)
            setAverageSellReal(averageSellReal)
        }
    }, [NavigatorReducer.getNavigatorProcessBool])

    const chartOptions = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false
            }
        },
        stroke: {
            curve: 'smooth',
            width: [3, 3],
            dashArray: [0, 10],
            colors: [theme.colors.error.main, theme.colors.primary.main]
        },
        fill: {
            opacity: [1, 0.2]
        },
        theme: {
            mode: theme.palette.mode
        },
        markers: {
            hover: {
                sizeOffset: 1
            },
            shape: 'circle',
            size: 5,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeColors: theme.colors.alpha.white[100],
            colors: [theme.colors.error.main, theme.colors.primary.main]
        },
        colors: [theme.colors.error.main, theme.colors.primary.main],
        labels: days,
        dataLabels: {
            enabled: false
        },
        legend: {
            labels: {
                useSeriesColors: true
            },
            itemMargin: {
                horizontal: 15,
                vertical: 15
            },
            show: true
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            labels: {
                show: true
            }
        },
        yaxis: {
            show: true,
            labels: {
                show: true
            },
            tickAmount: 5,
            min: minValue,
            max: maxValue
        },
        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true,

                },

            },
            strokeDashArray: 5,
            borderColor: theme.colors.alpha.black[10]
        }
    };

    const chartData = [
        {
            type: 'line',
            name: 'Previous Period',
            data: listAverageSell
        },
        {
            type: 'area',
            name: 'Current Period',
            data: listAverageSellReal
        },
    ];


    return (
        <div className="col-md-12 mt-2 mt-4 mb-4 ">
            <div className={'px-3 d-flex justify-content-between'}>
                <div className="textHeader">
                    <h2>{t('Sidebar1.navigator')}</h2>
                </div>
                {
                    users.branches.length > 0 ?
                        <div>
                            <select className={'form-control'} name="" id="" value={mainBranchId}
                                    onChange={changeBranch}>
                                {
                                    users.branches.map(item =>
                                        <option value={item.id}>{item.name}</option>)
                                }
                            </select>
                        </div> : ''
                }
                <div>
                </div>
                <div>
                    <input type="month" className={'form-control'} value={month} onChange={getMonth}/>
                </div>
                <div className={'d-flex'}>
                    {
                        NavigatorReducer.navigator ?
                            <div>
                                {
                                    NavigatorReducer?.navigator?.updateable ? <button className={'btn btn-success'}
                                                                                      onClick={() => getNavigatorProcessUpdate(mainBranchId ? mainBranchId : users.branchId)}>{t('baza.navigator')}</button>
                                        : ''
                                }
                                {
                                    users.deleteNavigator ?
                                <button className={'btn btn-danger'}
                                        onClick={() => deleteNavigator(mainBranchId ? mainBranchId : users.branchId)}>{t('baza.navigatoru')}
                                </button> :"" }
                            </div> :
                            <div>
                                {
                                    users.addNavigator ?
                                <button className={'btn btn-primary'} onClick={navigationToggle}>{t('baza.navigatory')}
                                </button> :""}
                            </div>

                    }
                    <div>
                        <button className={'btn btn-info'}>{t('Sidebar.1')}</button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="rowStyle1">
                    {
                        NavigatorReducer.navigator ?
                            <div>
                                <h2>{t('baza.bs')} {formatDate(NavigatorReducer.navigator.startDate)}</h2>
                                <h2>{t('baza.bm')} {NavigatorReducer.navigator.initial}</h2>
                                <h2>{t('Hamkorlar.eslatma')} {NavigatorReducer.navigator.description}</h2>
                                <h2>{t('baza.ts')} {formatDate(NavigatorReducer.navigator.endDate)}</h2>
                                <h2>{t('baza.tm')}  {NavigatorReducer.navigator.goal}</h2>
                            </div> : ''
                    }

                    {
                        NavigatorReducer.navigator ?
                            <Card
                                sx={{
                                    height: '100%'
                                }}
                            >
                                <CardHeader
                                    title={
                                        <>
                                            {t('Activity')}{' '}
                                            <Typography
                                                variant="body2"
                                                component="span"
                                                fontWeight="bold"
                                                color="text.secondary"
                                            >
                                                ({t('burned calories')})
                                            </Typography>
                                        </>
                                    }
                                />
                                <Divider/>
                                <Divider/>
                                <Box p={2}>

                                    <>
                                        <Chart
                                            options={chartOptions}
                                            series={chartData}
                                            type="line"
                                            height={322}
                                        />
                                        <Divider/>
                                        <Box
                                            mt={2}
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            {/*<Button*/}
                                            {/*    size="small"*/}
                                            {/*    variant="outlined"*/}
                                            {/*    sx={{*/}
                                            {/*        borderWidth: '2px',*/}
                                            {/*        '&:hover': {*/}
                                            {/*            borderWidth: '2px'*/}
                                            {/*        }*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    {t('View more activity charts')}*/}
                                            {/*</Button>*/}
                                        </Box>
                                    </>

                                </Box>
                            </Card> : <Typography variant={'h3'} align={'center'} mt={5}>
                                {NavigatorReducer.message}
                            </Typography>
                    }

                </div>


            </div>


            <Modal isOpen={navigationModal} toggle={navigationToggle}>
                <ModalHeader>
                    <h2>{t('baza.navigatory')}</h2>
                </ModalHeader>
                <ModalBody>
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="description">{t('Hamkorlat.eslatma')}</label>
                                <input type="text" id={'description'}
                                       className={'form-control'} {...register('description')}
                                       placeholder={t('Hamkorlar.eslatma')}/>

                            </div>
                            <div className="col-md-12">
                                {
                                    NavigatorReducer?.averageSum ?
                                        <h2>{NavigatorReducer?.averageSum} {t('Hamkorlar.sum')}</h2> : ''
                                }
                            </div>
                            <div className="col-md-12 p-0 d-flex justify-content-between">
                                <div className={'col-md-6 '}>
                                    <label htmlFor="goal">{t('baza.Maqsad')}</label>
                                    <input type="number" id={'goal'}
                                           className={'form-control'} {...register('goal', {required: true})}
                                           placeholder={errors.goal ? errors.goal?.type === "required" && "Maqsadni kiriting" : 'Maqsad'}
                                    />
                                </div>
                                <div className={'col-md-6'}>
                                    <label htmlFor="date">{t('Purchase.7')}</label>
                                    <input type="date" id={'date'}
                                           className={'form-control'} {...register('endDate', {required: true})}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-end">
                                <button type={'button'} onClick={navigationToggle}
                                        className={'btn btn-danger mx-4'}>{t('Hamkorlar.chiqish')}                                </button>
                                <button type={'submit'} className={'btn btn-success'}>{t('Hamkorlar.saqlash')}</button>
                            </div>
                        </div>
                    </form>

                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>

        </div>
    )
}

export default connect((NavigatorReducer, users), {
    getNavigator,
    saveNavigator,
    getNavigatorAverage,
    getNavigatorProcess,
    deleteNavigator,
    getNavigatorProcessUpdate
})(Navigator1)
